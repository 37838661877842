import React, { useState, useEffect } from 'react';
import { 
  Autocomplete, 
  TextField, 
  Chip, 
  Box,
  IconButton,
  InputAdornment,
  Tooltip,
  Paper
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useAddVocab } from '../hooks/useAddVocab';

export function AttributeInput({ 
  languageId, 
  initialAttributes = {},
  onChange,
  disabled = false
}) {
  const [attributeKey, setAttributeKey] = useState('');
  const [attributeValue, setAttributeValue] = useState('');
  const [keyOptions, setKeyOptions] = useState([]);
  const [valueOptions, setValueOptions] = useState([]);
  const [attributes, setAttributes] = useState(initialAttributes);
  const { getAttributeSuggestions } = useAddVocab();

  useEffect(() => {
    setAttributes(initialAttributes);
  }, [initialAttributes]);

  useEffect(() => {
    const loadKeyOptions = async () => {
      if (attributeKey.length >= 1) {
        const suggestions = await getAttributeSuggestions(
          languageId,
          null,
          attributeKey
        );
        setKeyOptions(suggestions.keys || []);
      } else {
        setKeyOptions([]);
      }
    };
    loadKeyOptions();
  }, [attributeKey, languageId, getAttributeSuggestions]);

  useEffect(() => {
    const loadValueOptions = async () => {
      if (attributeKey && attributeValue.length >= 1) {
        const suggestions = await getAttributeSuggestions(
          languageId,
          attributeKey,
          attributeValue
        );
        setValueOptions(suggestions.values || []);
      } else {
        setValueOptions([]);
      }
    };
    loadValueOptions();
  }, [attributeKey, attributeValue, languageId, getAttributeSuggestions]);

  const handleAddAttribute = () => {
    if (attributeKey && attributeValue) {
      const newAttributes = {
        ...attributes,
        [attributeKey]: attributeValue
      };
      setAttributes(newAttributes);
      onChange(newAttributes);
      setAttributeKey('');
      setAttributeValue('');
    }
  };

  const handleRemoveAttribute = (key) => {
    const newAttributes = { ...attributes };
    delete newAttributes[key];
    setAttributes(newAttributes);
    onChange(newAttributes);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && attributeKey && attributeValue) {
      event.preventDefault();
      handleAddAttribute();
    }
  };

  const formatAttribute = (key, value) => {
    const valueLower = value.toLowerCase();
    const keyLower = key.toLowerCase();
    
    if (valueLower.includes(keyLower)) {
      return value;
    }
    return `${key}: ${value}`;
  };

  return (
    <Box>
      <Box display="flex" gap={2} mb={2} alignItems="center">
        <Autocomplete
          freeSolo
          value={attributeKey}
          onChange={(_, newValue) => setAttributeKey(newValue || '')}
          onInputChange={(_, newValue) => setAttributeKey(newValue)}
          options={keyOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Attribut-Name"
              placeholder="z.B. ISBN, Lektion"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <Tooltip title="Geben Sie hier den Namen des Attributs ein, z.B. 'ISBN' für eine Buchreferenz oder 'Lektion' für einen Lektionsverweis. Die Attribute helfen Ihnen später beim Filtern und Organisieren Ihrer Vokabeln.">
                      <HelpOutlineIcon fontSize="small" color="action" />
                    </Tooltip>
                  </>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && attributeKey && !attributeValue) {
                  e.preventDefault();
                  document.getElementById('attribute-value-input')?.focus();
                }
              }}
            />
          )}
          disabled={disabled}
          sx={{ minWidth: '200px' }}
        />
        
        <Autocomplete
          freeSolo
          value={attributeValue}
          onChange={(_, newValue) => setAttributeValue(newValue || '')}
          onInputChange={(_, newValue) => setAttributeValue(newValue)}
          options={valueOptions}
          disabled={disabled || !attributeKey}
          renderInput={(params) => (
            <TextField
              {...params}
              id="attribute-value-input"
              label="Attribut-Wert"
              placeholder={attributeKey ? `Wert für ${attributeKey}` : "Zuerst Attribut-Namen eingeben"}
              size="small"
              onKeyDown={handleKeyDown}
            />
          )}
          sx={{ flexGrow: 1 }}
        />
        
        <IconButton 
          onClick={handleAddAttribute}
          disabled={disabled || !attributeKey || !attributeValue}
          color="primary"
        >
          <AddIcon />
        </IconButton>
      </Box>
      
      <Paper variant="outlined" sx={{ p: 1, mb: 1 }}>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {Object.entries(attributes).map(([key, value]) => (
            <Chip
              key={key}
              label={formatAttribute(key, value)}
              onDelete={() => handleRemoveAttribute(key)}
              disabled={disabled}
              sx={{ 
                maxWidth: '100%',
                '& .MuiChip-label': {
                  whiteSpace: 'normal',
                  overflow: 'visible',
                  textOverflow: 'clip',
                  display: 'block'
                }
              }}
            />
          ))}
        </Box>
      </Paper>
    </Box>
  );
}