import { useState, useCallback, useRef } from 'react';
import openai from './openai';

const useOpenAIChat = () => {
    const [response, setResponse] = useState('');
    const [streamResponse, setStreamResponse] = useState('');
    const messagesRef = useRef([
        { role: "system", content: "Du bist ein Experte für Vokabeltraining und hilfst deinem Schüler besser zu lernen, gemacht Fehler zu verstehen und diesen Fehler in Zukunft zu vermeiden." }
    ]);
    const isProcessingRef = useRef(false);

    const getResponse = useCallback(async (prompt) => {
        if (isProcessingRef.current) {
            console.log('Already processing a request');
            return;
        }

        try {
            isProcessingRef.current = true;
            setStreamResponse('');
            
            const messagesToSend = [
                ...messagesRef.current,
                { role: "user", content: prompt }
            ];

            const stream = await openai.chat.completions.create({
                messages: messagesToSend,
                model: "gpt-4",
                stream: true,
            });

            let fullResponse = '';

            for await (const chunk of stream) {
                if (chunk.choices[0]?.delta?.content) {
                    const content = chunk.choices[0].delta.content;
                    fullResponse += content;
                    setStreamResponse(prevStream => prevStream + content);
                }
            }

            setResponse(fullResponse);
            messagesRef.current = [
                ...messagesRef.current,
                { role: "user", content: prompt },
                { role: "assistant", content: fullResponse }
            ];

            return fullResponse;

        } catch (error) {
            console.error('Error fetching response from OpenAI:', error);
            throw error;
        } finally {
            isProcessingRef.current = false;
        }
    }, []);

    return { 
        response, 
        streamResponse, 
        getResponse,
    };
};

export default useOpenAIChat;