import { useState } from 'react';
import { Box, Typography, IconButton, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';

const VocabDisplay = ({ currentVocab, isReverseOrder, hasError, onErrorTipClick, language }) => {
  const [showTags, setShowTags] = useState(false);

  const handleTagsClick = (e) => {
    e.stopPropagation();
    setShowTags(!showTags);
  };

  const hasAttributes = currentVocab?.attributes && Object.keys(currentVocab.attributes).length > 0;

  const formatAttribute = (key, value) => {
    // Check if the value already contains the key
    const valueLower = value.toLowerCase();
    const keyLower = key.toLowerCase();
    
    if (valueLower.includes(keyLower)) {
      return value;
    } else {
      return `${key}: ${value}`;
    }
  };

  return (
    <Box display="flex" alignItems="flex-start">
      <Box>
        <Typography variant="h5" gutterBottom>
          Übersetze: {currentVocab ? (isReverseOrder ? currentVocab.foreign : currentVocab.german) : 'Fertig!'}
        </Typography>
        {hasAttributes && (
          <Collapse in={showTags}>
            <Typography component="div" variant="body2" color="textSecondary">
              {Object.entries(currentVocab.attributes).map(([key, value]) => (
                <Box component="span" key={key} sx={{ mr: 1 }}>
                  {formatAttribute(key, value)}
                </Box>
              ))}
            </Typography>
          </Collapse>
        )}
      </Box>
      {currentVocab && hasError && (
        <IconButton onClick={onErrorTipClick} size="small" sx={{ ml: 1 }}>
          <InfoIcon />
        </IconButton>
      )}
      {hasAttributes && (
        <IconButton
          size="small"
          onClick={handleTagsClick}
          sx={{ 
            ml: 1, 
            transform: showTags ? 'rotate(180deg)' : 'none', 
            transition: 'transform 0.3s'
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default VocabDisplay;