import React, { useState } from 'react';
import { useLogin, getErrorMessage } from '../hooks/useLogin';
import { TextField, Button, Card, CardContent, Typography, Container } from '@mui/material';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";

export default function Login({ navigateTo }) {
  const { email, setEmail, password, setPassword, login, error: loginHookError } = useLogin();
  const [resetError, setResetError] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError(null);
    try {
      await login();
      navigateTo('/');
    } catch (error) {
      console.error('Login error in component:', error);
      setLoginError(loginHookError || getErrorMessage(error.code));
    }
  }

  const handleResetPassword = async () => {
    if (!email) {
      setResetError('Bitte gib deine E-Mail-Adresse ein.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Check deine Mails! Wir haben dir Anweisungen zum Zurücksetzen deines Passworts geschickt.');
      setResetError(null);
    } catch (error) {
      console.error('Password reset error:', error);
      setResetError(getErrorMessage(error.code));
    }
  }

  const handleResendVerificationEmail = async () => {
    if (!email || !password) {
      setLoginError('Bitte gib deine E-Mail und dein Passwort ein.');
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      alert('Alles klar! Wir haben dir die Bestätigungs-Mail nochmal geschickt.');
    } catch (error) {
      console.error('Verification email error:', error);
      setLoginError(getErrorMessage(error.code));
    }
  }

  return (
    <Container maxWidth="xs">
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>Los geht's!</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Deine E-Mail"
              type="email"
              required
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              error={!!loginError || !!resetError}
            />
            <TextField
              label="Dein Passwort"
              type="password"
              required
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              error={!!loginError}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '20px' }}
            >
              Einloggen
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ marginTop: '20px' }}
              onClick={handleResetPassword}
            >
              Passwort vergessen?
            </Button>
            {loginError && (
              <>
                <Typography color="error" style={{ marginTop: '20px' }}>{loginError}</Typography>
                {loginError.includes('Bestätigungs-Mail') && (
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    fullWidth 
                    style={{ marginTop: '20px' }} 
                    onClick={handleResendVerificationEmail}
                  >
                    Bestätigungs-Mail nochmal senden
                  </Button>
                )}
              </>
            )}
            {resetError && <Typography color="error" style={{ marginTop: '20px' }}>{resetError}</Typography>}
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}