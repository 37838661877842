import React, { useState, useEffect } from 'react';
import { useUserLanguages } from '../hooks/useUserLanguages';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import useAddLanguage from '../hooks/useAddLanguage';
import useDeleteLanguage from '../hooks/useDeleteLanguage';
import useChangeLanguage from '../hooks/useChangeLanguage';
import { 
  TextField, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction, 
  IconButton, 
  Typography, 
  Checkbox, 
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TopicIcon from '@mui/icons-material/Topic';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage as setReduxLanguage } from '../redux/languageSlice';
import { selectIsPremium } from '../redux/userSlice';
import { useNavigate } from 'react-router-dom';

export default function LanguageManager() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const language = useSelector(state => state.language);
    const isPremium = useSelector(selectIsPremium);
    const [newLanguage, setNewLanguage] = useState('');
    const [isSubjectTopic, setIsSubjectTopic] = useState(false);
    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
    const [upgradeMessage, setUpgradeMessage] = useState('');

    const { addLanguage } = useAddLanguage();
    const deleteLanguage = useDeleteLanguage();
    const [user] = useAuthState(auth);
    const { documents: languages, error } = useUserLanguages('languageList', user ? user.uid : null);

    // Verbesserte useEffect-Logik für die automatische Sprachauswahl
    useEffect(() => {
        if (languages && languages.length > 0) {
            // Sortiere die Sprachen alphabetisch
            const sortedLanguages = [...languages].sort((a, b) => 
                a.language.localeCompare(b.language)
            );

            // Für Free User: Immer die erste (alphabetisch sortierte) Sprache auswählen
            if (!isPremium) {
                dispatch(setReduxLanguage(sortedLanguages[0]));
                return;
            }

            // Für Premium User: Aktuelle Sprache beibehalten, wenn sie existiert
            const currentLanguage = sortedLanguages.find(lang => 
                lang.id === language?.id
            );

            // Wenn keine aktuelle Sprache existiert oder sie ungültig ist,
            // die erste Sprache auswählen
            if (!currentLanguage) {
                dispatch(setReduxLanguage(sortedLanguages[0]));
            }
        }
    }, [languages, isPremium, dispatch, language?.id]);
  
    const [addLanguageError, setAddLanguageError] = useState(null);

    const handleAdd = async () => {
        if (!newLanguage) return;

        // Prüfen ob Free User bereits eine Sprache hat
        if (!isPremium && languages?.length >= 1) {
            setUpgradeMessage('Als kostenloser Nutzer können Sie nur eine Sprache anlegen. Upgraden Sie auf Premium, um unbegrenzt viele Sprachen anzulegen!');
            setShowUpgradeDialog(true);
            return;
        }

        const result = await addLanguage(newLanguage, isSubjectTopic);
        if (result.message) {
            setAddLanguageError(result.message);
        } else {
            // Wenn es die erste Sprache ist oder der User Premium ist,
            // setze sie als aktive Sprache
            if (languages.length === 0 || isPremium) {
                dispatch(setReduxLanguage(result));
            }
            setNewLanguage('');
            setIsSubjectTopic(false);
        }
    };

    const handleDelete = async (languageId) => {
        try {
            const result = await deleteLanguage(languageId);
            
            // Nach dem Löschen: Stelle sicher, dass eine gültige Sprache ausgewählt ist
            if (languages && languages.length > 0) {
                const sortedLanguages = [...languages]
                    .filter(lang => lang.id !== languageId)
                    .sort((a, b) => a.language.localeCompare(b.language));
                
                if (!isPremium || language.id === languageId) {
                    // Für Free User oder wenn die aktive Sprache gelöscht wurde:
                    // Wähle die erste Sprache
                    dispatch(setReduxLanguage(sortedLanguages[0]));
                }
            }
        } catch (error) {
            console.error('Error deleting language: ', error);
        }
    };

    const changeLanguage = useChangeLanguage();
    const [isEditing, setIsEditing] = useState(false);
    const [editedLanguage, setEditedLanguage] = useState('');

    const handleEdit = async (languageId) => {
        if (isEditing) {
            try {
                const result = await changeLanguage(languageId, editedLanguage);
                setIsEditing(false);
                setEditedLanguage('');
                const updatedLanguage = { ...language, language: editedLanguage };
                // Nur aktualisieren, wenn es die erste Sprache ist oder der User Premium ist
                if (isPremium || languages.indexOf(language) === 0) {
                    dispatch(setReduxLanguage(updatedLanguage));
                }
            } catch (error) {
                console.error('Error changing language: ', error);
            }
        } else {
            setIsEditing(true);
            const languageToEdit = languages.find(lang => lang.id === languageId);
            setEditedLanguage(languageToEdit.language);
            if (isPremium || languages.indexOf(languageToEdit) === 0) {
                dispatch(setReduxLanguage(languageToEdit));
            }
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditedLanguage('');
    };

    const handleLanguageClick = (languageItem, index) => {
        if (isEditing) return;

        // Wenn Premium User, normale Funktion
        if (isPremium) {
            dispatch(setReduxLanguage(languageItem));
            return;
        }

        // Wenn Free User, nur erste Sprache erlauben
        if (index === 0) {
            dispatch(setReduxLanguage(languageItem));
        } else {
            setUpgradeMessage('Als kostenloser Nutzer können Sie nur die erste Sprache in Ihrer Liste verwenden. Upgraden Sie auf Premium, um alle Ihre Sprachen zu nutzen!');
            setShowUpgradeDialog(true);
        }
    };

    const handleUpgrade = () => {
        setShowUpgradeDialog(false);
        navigate('/subscription');
    };

    return (
        <div>
            {!isPremium && languages?.length > 1 && (
                <Alert 
                    severity="info" 
                    icon={<WorkspacePremiumIcon />}
                    sx={{ mb: 2 }}
                >
                    Als kostenloser Nutzer können Sie nur die erste Sprache in Ihrer Liste verwenden.
                </Alert>
            )}
            
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                <TextField 
                    label="Neue Sprache hinzufügen" 
                    value={newLanguage} 
                    onChange={(e) => setNewLanguage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleAdd();
                            e.preventDefault();
                        }
                    }}
                    variant="outlined"
                    fullWidth
                />
                <Tooltip title="Sachthema">
                    <Checkbox
                        icon={<TopicIcon />}
                        checkedIcon={<TopicIcon />}
                        checked={isSubjectTopic}
                        onChange={(e) => setIsSubjectTopic(e.target.checked)}
                        style={{ marginLeft: 8 }}
                    />
                </Tooltip>
            </div>
            
            {addLanguageError && <Typography color="error">{addLanguageError}</Typography>}
            
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleAdd} 
                startIcon={<AddIcon />} 
                style={{ marginTop: 8 }}
            >
                Hinzufügen
            </Button>

            <List>
                {languages && [...languages]
                    .sort((a, b) => a.language.localeCompare(b.language))
                    .map((languageItem, index) => (
                    <ListItem 
                        key={languageItem.id} 
                        button 
                        onClick={() => handleLanguageClick(languageItem, index)}
                        sx={{
                            opacity: (!isPremium && index > 0) ? 0.5 : 1,
                            '&:hover': {
                                opacity: (!isPremium && index > 0) ? 0.5 : 0.8
                            }
                        }}
                    >
                        {isEditing && languageItem.id === language.id ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <TextField 
                                    value={editedLanguage} 
                                    onChange={(e) => setEditedLanguage(e.target.value)}
                                />
                                <Box sx={{ mt: 1 }}>
                                    <IconButton edge="end" aria-label="save" onClick={() => handleEdit(languageItem.id)}>
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="cancel" onClick={handleCancelEdit}>
                                        <CancelIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <ListItemText 
                                    primary={languageItem.language} 
                                    primaryTypographyProps={{ 
                                        color: languageItem.id === language.id ? 'primary' : 'initial'
                                    }}
                                />
                                {(isPremium || index === 0) && (
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(languageItem.id)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(languageItem.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                )}
                            </>
                        )}
                    </ListItem>
                ))}
            </List>

            <Dialog 
                open={showUpgradeDialog} 
                onClose={() => setShowUpgradeDialog(false)}
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <WorkspacePremiumIcon color="primary" />
                        <Typography>Premium-Funktion</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography>{upgradeMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowUpgradeDialog(false)}>Abbrechen</Button>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={handleUpgrade}
                        startIcon={<WorkspacePremiumIcon />}
                    >
                        Jetzt upgraden
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}