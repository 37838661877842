import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../firebase/config';
import { fetchSubscriptionStatus } from '../redux/userSlice';
import { 
  Container, 
  Typography, 
  Box, 
  Paper,
  Button,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import {
  AlertCircle,
  Calendar,
  CheckCircle2,
  Info,
  XCircle,
  ArrowLeft,
  Timer,
  Star
} from 'lucide-react';

const SubscriptionCancellation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [cancellationSuccess, setCancellationSuccess] = useState(false);
  const [activeUntilDate, setActiveUntilDate] = useState(null);
  const subscription = useSelector(state => state.user.subscription);

  useEffect(() => {
    dispatch(fetchSubscriptionStatus());
  }, [dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('action') === 'cancel') {
      setShowConfirmDialog(true);
    }
  }, []);

  const calculateEndDate = (startDate) => {
    if (!startDate) return null;
    const endDate = new Date(startDate);
    endDate.setFullYear(endDate.getFullYear() + 1); // Add one year for yearly subscriptions
    return endDate;
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('de-DE', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  };

  const handleCancel = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch('https://brainwizzr-backend-41f7251d9520.herokuapp.com/api/subscriptions/cancel', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || data.details || 'Fehler beim Kündigen des Abonnements');
      }

      setCancellationSuccess(true);
      if (data.activeUntil) {
        setActiveUntilDate(new Date(data.activeUntil));
      }
      dispatch(fetchSubscriptionStatus());
    } catch (err) {
      console.error('Cancel error:', err);
      setError(err.message || 'Ein unerwarteter Fehler ist aufgetreten');
    } finally {
      setLoading(false);
      setShowConfirmDialog(false);
    }
  };

  const handleBack = () => {
    navigate('/profile');
  };

  const formatPlanText = (plan) => {
    switch (plan) {
      case 'monthly':
        return 'Monatsabo (7,99€/Monat)';
      case 'yearly':
        return 'Jahresabo (79,99€/Jahr)';
      default:
        return plan;
    }
  };

  if (loading && !cancellationSuccess) {
    return (
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center', py: 8 }}>
          <CircularProgress size={40} />
          <Typography sx={{ mt: 2 }}>
            Kündigungsanfrage wird verarbeitet...
          </Typography>
        </Box>
      </Container>
    );
  }

  const endDate = calculateEndDate(subscription?.subscription?.startDate);

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Button
          startIcon={<ArrowLeft />}
          onClick={handleBack}
          sx={{ mb: 4 }}
        >
          Zurück zum Profil
        </Button>

        <Paper sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            Premium-Abo Verwaltung
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {cancellationSuccess ? (
            <Box>
              <Alert 
                severity="info" 
                icon={<Info />}
                sx={{ mb: 3 }}
              >
                Dein Premium-Abo wurde erfolgreich gekündigt. Du kannst die Premium-Features noch bis zum {formatDate(endDate)} nutzen.
              </Alert>
              
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Calendar className="w-5 h-5 text-blue-500" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Premium aktiv bis"
                    secondary={formatDate(endDate)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Star className="w-5 h-5 text-blue-500" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Premium Features"
                    secondary="Bleiben bis zum Ablaufdatum vollständig verfügbar"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Info className="w-5 h-5 text-blue-500" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Was passiert danach?"
                    secondary="Dein Account wird automatisch auf die kostenlose Version umgestellt. Deine gespeicherten Daten bleiben erhalten."
                  />
                </ListItem>
              </List>

              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Button 
                  variant="contained"
                  onClick={handleBack}
                >
                  Zurück zum Profil
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Alert 
                severity="warning" 
                icon={<AlertCircle />}
                sx={{ mb: 3 }}
              >
                Möchtest du dein Premium-Abo wirklich kündigen?
              </Alert>

              <List>
                <ListItem>
                  <ListItemIcon>
                    <Star className="w-5 h-5 text-blue-500" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Aktueller Plan"
                    secondary={formatPlanText(subscription?.subscription?.plan)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Calendar className="w-5 h-5 text-blue-500" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Premium aktiv bis"
                    secondary={formatDate(endDate)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Timer className="w-5 h-5 text-blue-500" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Premium-Zugang"
                    secondary="Bleibt bis zum Ende der aktuellen Abrechnungsperiode erhalten"
                  />
                </ListItem>
              </List>

              <Divider sx={{ my: 3 }} />

              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleBack}
                >
                  Abbrechen
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setShowConfirmDialog(true)}
                  startIcon={<XCircle />}
                >
                  Jetzt kündigen
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>

      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>
          Premium-Abo kündigen?
        </DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Bist du sicher, dass du dein Premium-Abo kündigen möchtest?
          </Typography>
          <Typography>
            Du behältst deinen Premium-Zugang noch bis zum Ende der aktuellen Abrechnungsperiode
            {endDate ? ` (${formatDate(endDate)})` : ''}.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowConfirmDialog(false)}
            disabled={loading}
          >
            Abbrechen
          </Button>
          <Button 
            onClick={handleCancel}
            color="error"
            variant="contained"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <XCircle />}
          >
            {loading ? 'Wird gekündigt...' : 'Ja, kündigen'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SubscriptionCancellation;
