import { auth } from '../firebase/config';
import { db } from '../firebase/config';
import { doc, updateDoc, getDoc, writeBatch } from 'firebase/firestore';

export function useChangeVocab() {
  const changeVocab = async (docId, foreign, german, knownCounter, languageId, nextTraining, additionalInfo, attributes = {}) => {
    const userId = auth.currentUser?.uid;
    const vocabRef = doc(db, 'vocabList', docId);

    await updateDoc(vocabRef, { 
      foreign: foreign,
      german: german,
      additionalInfo,
      knownCounter: Number(knownCounter),
      languageId: languageId,
      nextTraining: nextTraining,
      userId: userId,
      attributes: attributes
    });
  };

  // Funktion für die Massenbearbeitung von Attributen
  const bulkUpdateAttributes = async (vocabIds, attributesToAdd, attributesToRemove = []) => {
    const batch = writeBatch(db);
    
    for (const docId of vocabIds) {
      const vocabRef = doc(db, 'vocabList', docId);
      
      // Wir müssen zuerst das aktuelle Dokument abrufen
      const docSnap = await getDoc(vocabRef);
      if (docSnap.exists()) {
        const currentAttributes = docSnap.data()?.attributes || {};
        
        // Neue Attribute hinzufügen
        const updatedAttributes = {
          ...currentAttributes,
          ...attributesToAdd
        };
        
        // Attribute entfernen, wenn gewünscht
        attributesToRemove.forEach(key => {
          delete updatedAttributes[key];
        });
        
        batch.update(vocabRef, {
          attributes: updatedAttributes
        });
      }
    }
    
    await batch.commit();
  };

  // Funktion zum Aktualisieren einzelner Attribute
  const updateAttributes = async (docId, attributes) => {
    const vocabRef = doc(db, 'vocabList', docId);
    await updateDoc(vocabRef, { attributes });
  };

  return {
    changeVocab,
    bulkUpdateAttributes,
    updateAttributes
  };
}