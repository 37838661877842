import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config';
import { db } from '../firebase/config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ensureDefaultFields, validateUserFields } from '../utils/fieldUtils';

export const errorMessages = {
  'auth/invalid-credential': 'Ups, da stimmt was nicht. Überprüf mal deine E-Mail und dein Passwort!',
  'auth/user-not-found': 'Hm, wir kennen diese E-Mail-Adresse nicht. Schon registriert?',
  'auth/wrong-password': 'Oh je, das Passwort passt nicht. Probiers nochmal!',
  'auth/email-already-in-use': 'Diese E-Mail ist schon vergeben. Vielleicht willst du dich einloggen?',
  'auth/weak-password': 'Dein Passwort könnte stärker sein. Wie wärs mit was Kreativerem?',
  'auth/user-disabled': 'Dein Konto macht gerade Pause. Sprich mal mit unserem Support-Team!',
  'auth/too-many-requests': 'Wow, nicht so schnell! Mach mal ne kurze Pause und versuchs dann nochmal.',
  'auth/email-not-verified': 'Fast geschafft! Schau mal in deine Mails und bestätige deine E-Mail-Adresse.',
};

export function getErrorMessage(errorCode) {
  return errorMessages[errorCode] || 'Hoppla! Da ist was schiefgelaufen. Versuchs später nochmal, okay?';
}

export function useLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const login = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        throw new Error('auth/email-not-verified');
      }

      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        const newUserData = ensureDefaultFields({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName || 'Anonymous User',
          createdAt: new Date().toISOString(),
          lastLogin: new Date().toISOString(),
          verified: user.emailVerified,
          lastUpdated: new Date().toISOString()
        });

        const validation = validateUserFields(newUserData);
        if (!validation.isValid) {
          console.error('Validation errors:', validation.errors);
          throw new Error('User data validation failed');
        }

        await setDoc(userDocRef, newUserData);
      } else {
        const existingData = userDoc.data();
        const updatedData = ensureDefaultFields(existingData);
        
        await setDoc(userDocRef, {
          ...updatedData,
          lastLogin: new Date().toISOString(),
          lastUpdated: new Date().toISOString(),
          verified: user.emailVerified,
          displayName: user.displayName || existingData.displayName || 'Anonymous User'
        }, { merge: true });
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.message === 'auth/email-not-verified') {
        setError(getErrorMessage('auth/email-not-verified'));
      } else {
        setError(getErrorMessage(error.code));
      }
      throw error;
    }
  };

  return { email, setEmail, password, setPassword, login, error };
}