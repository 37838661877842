import React, { useState } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  Chip,
  IconButton,
  Collapse
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const VocabCard = ({ vocabPair, showGerman, onCardClick }) => {
  const [showTags, setShowTags] = useState(false);

  const handleTagsClick = (e) => {
    e.stopPropagation();
    setShowTags(!showTags);
  };

  const hasAttributes = vocabPair.attributes && Object.keys(vocabPair.attributes).length > 0;

  const formatAttribute = (key, value) => {
    const valueLower = value.toLowerCase();
    const keyLower = key.toLowerCase();
    
    if (valueLower.includes(keyLower)) {
      return value;
    } else {
      return `${key}: ${value}`;
    }
  };

  return (
    <Card elevation={2} sx={{ marginBottom: 2, cursor: 'pointer' }} onClick={() => onCardClick(vocabPair.id)}>
      <CardContent>
        <Box>
          {showGerman ? (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" component="div" sx={{ fontWeight: 'medium' }}>
                  {vocabPair.german}
                </Typography>
                {hasAttributes && (
                  <IconButton 
                    size="small" 
                    onClick={handleTagsClick}
                    sx={{ transform: showTags ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s' }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </Box>
              {hasAttributes && (
                <Collapse in={showTags}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                    {Object.entries(vocabPair.attributes).map(([key, value]) => (
                      <Chip
                        key={key}
                        label={formatAttribute(key, value)}
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </Collapse>
              )}
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" component="div" sx={{ fontWeight: 'medium' }}>
                  {vocabPair.foreign}
                </Typography>
                {hasAttributes && (
                  <IconButton 
                    size="small" 
                    onClick={handleTagsClick}
                    sx={{ transform: showTags ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s' }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </Box>
              {vocabPair.additionalInfo && (
                <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
                  {vocabPair.additionalInfo}
                </Typography>
              )}
              {hasAttributes && (
                <Collapse in={showTags}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                    {Object.entries(vocabPair.attributes).map(([key, value]) => (
                      <Chip
                        key={key}
                        label={formatAttribute(key, value)}
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </Collapse>
              )}
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default VocabCard;