import React from 'react';
import { motion } from 'framer-motion';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { SparklesIcon } from 'lucide-react';

const PremiumButton = ({ onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <Button
        onClick={onClick}
        variant="contained"
        startIcon={
          <motion.div
            animate={{ 
              opacity: [1, 0.7, 1],
              scale: [1, 1.1, 1]
            }}
            transition={{ 
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut" 
            }}
          >
            <SparklesIcon size={16} className="text-black" />
          </motion.div>
        }
        sx={{
          background: 'linear-gradient(90deg, #FFD700 0%, #FFA726 100%)',
          color: 'black',
          fontWeight: 600,
          fontSize: '0.875rem',
          textTransform: 'none',
          padding: '6px 12px',
          minHeight: '32px',
          borderRadius: '16px',
          boxShadow: '0 2px 8px rgba(255, 215, 0, 0.3)',
          '&:hover': {
            background: 'linear-gradient(90deg, #FFD700 20%, #FFA726 100%)',
            boxShadow: '0 4px 12px rgba(255, 215, 0, 0.4)',
          }
        }}
      >
        {isMobile ? 'Premium' : 'Premium'}
      </Button>
    </motion.div>
  );
};

export default PremiumButton;