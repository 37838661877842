export const DEFAULT_USER_FIELDS = {
    roles: ['user'],
    verified: false,
    subscriptionStatus: 'inactive',
    subscription: {
      status: 'inactive',
      features: {
        maxVocabs: 50,
        aiTranslations: false,
        grammarCheck: false,
        speechRecognition: false
      }
    },
    activityLog: [],
    preferences: {
      theme: 'light',
      notifications: true,
      language: 'de'
    },
    usage: {
      totalVocabs: 0,
      lastActivity: null,
      streakDays: 0,
      totalPoints: 0
    },
    profile: {
      learningGoals: [],
      nativeLanguage: 'de',
      learningLanguages: []
    }
  };