import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { useSelector } from 'react-redux';
import useOpenAI from '../hooks/useOpenAI';
import useAiVoiceInput from '../hooks/useAiVoiceInput';
import useOpenAiTextToSpeech from '../hooks/useOpenAiTextToSpeech';
import { useVocabList } from '../hooks/useVocabList';
import { getVoiceToTextVocabularyPrompt } from '../utils/aiPrompt';
import { TagFilter } from './TagFilter';
import {
    Button,
    Container,
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    LinearProgress,
    Chip,
    CircularProgress,
    Snackbar,
    Switch,
    FormControlLabel,
    Divider
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ReactMarkdown from 'react-markdown';

export default function ConversationTraining({ userName }) {
    const [user] = useAuthState(auth);
    const userId = user ? user.uid : null;
    const language = useSelector(state => state.language);
    const { vocabPairs, error } = useVocabList(userId, language);
    const { response, getResponse, streamResponse } = useOpenAI();
    const { voiceResponse, getVoiceResponse } = useAiVoiceInput();
    const { playText, isStreaming } = useOpenAiTextToSpeech();

    // UI States
    const [vocabIndex, setVocabIndex] = useState(0);
    const [filteredVocabPairs, setFilteredVocabPairs] = useState([]);
    const [audioFile, setAudioFile] = useState(null);
    const [showAiResponse, setShowAiResponse] = useState(false);
    const [knownFilter, setKnownFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedWord, setSelectedWord] = useState('');
    const [isBlurred, setIsBlurred] = useState(true);
    const [isAdditionalInfoBlurred, setIsAdditionalInfoBlurred] = useState(true);
    const [isPlayTextEnabled, setIsPlayTextEnabled] = useState(true);
    const [tagFilters, setTagFilters] = useState({});

    // Response States
    const [currentStreamResponse, setCurrentStreamResponse] = useState('');
    const [isResponseReady, setIsResponseReady] = useState(false);
    const [responseSnackbarMessage, setResponseSnackbarMessage] = useState('');
    const [openResponseSnackbar, setOpenResponseSnackbar] = useState(false);
    const [lastProcessedResponse, setLastProcessedResponse] = useState(null);

    // Recording State
    const [recordingState, setRecordingState] = useState({
        isRecording: false,
        isProcessing: false
    });

    // Refs
    const processingTimeoutRef = useRef(null);
    const recorder = useRef(null);
    const lastVoiceResponseRef = useRef(null);

    // Filter vocab pairs
    useEffect(() => {
        if (vocabPairs) {
            let filtered = [...vocabPairs];
            
            // Bekanntheitsgrad-Filter
            if (knownFilter !== 'all') {
                filtered = filtered.filter(pair => pair.knownCounter === parseInt(knownFilter));
            }
            
            // Textsuche
            if (searchTerm) {
                filtered = filtered.filter(pair => 
                    pair.german.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    pair.foreign.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (pair.additionalInfo && pair.additionalInfo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (pair.attributes && Object.entries(pair.attributes).some(([key, value]) => 
                        key.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    ))
                );
            }

            // Tag-Filter
            const hasActiveTagFilters = Object.values(tagFilters).some(values => 
                Object.values(values).some(isActive => isActive)
            );

            if (hasActiveTagFilters) {
                filtered = filtered.filter(vocab => {
                    return Object.entries(tagFilters).every(([key, values]) => {
                        const activeValues = Object.entries(values)
                            .filter(([_, isActive]) => isActive)
                            .map(([value]) => value);
                        if (activeValues.length === 0) return true;
                        return vocab.attributes && 
                               vocab.attributes[key] && 
                               activeValues.includes(vocab.attributes[key]);
                    });
                });
            }
            
            setFilteredVocabPairs(filtered);
            
            // Reset selectedWord wenn das gefilterte Wort nicht mehr in der Liste ist
            if (selectedWord && !filtered.some(pair => pair.german === selectedWord)) {
                setSelectedWord('');
            }
        }
    }, [vocabPairs, knownFilter, searchTerm, tagFilters]);

    // Handle stream response
    useEffect(() => {
        if (streamResponse) {
            setCurrentStreamResponse(streamResponse);
        }
    }, [streamResponse]);
    
    // Handle final response with audio streaming
    useEffect(() => {
        if (response) {
            setCurrentStreamResponse('');
            setIsResponseReady(true);
            if (isPlayTextEnabled) {
                playText(response);
            }
        }
    }, [response, playText, isPlayTextEnabled]);

    // Process audio file
    useEffect(() => {
        if (audioFile) {
            getVoiceResponse(audioFile)
                .then(response => {
                    lastVoiceResponseRef.current = response;
                })
                .catch(error => {
                    console.error("Error processing audio:", error);
                    setResponseSnackbarMessage("Fehler bei der Sprachverarbeitung");
                    setOpenResponseSnackbar(true);
                });
        }
    }, [audioFile, getVoiceResponse]);

    // Voice Response processing
    useEffect(() => {
        if (voiceResponse && 
            voiceResponse !== lastProcessedResponse && 
            !recordingState.isProcessing && 
            filteredVocabPairs.length > 0 &&
            voiceResponse === lastVoiceResponseRef.current) {
            
            setLastProcessedResponse(voiceResponse);
            setRecordingState(prev => ({ ...prev, isProcessing: true }));

            const germanWord = filteredVocabPairs[vocabIndex].german;
            const expectedForeignWord = filteredVocabPairs[vocabIndex].foreign;
            
            setResponseSnackbarMessage(`KI-Trainer analysiert die Antwort.`);
            setOpenResponseSnackbar(true);

            getResponse(
                getVoiceToTextVocabularyPrompt(
                    germanWord,
                    expectedForeignWord,
                    language.language,
                    voiceResponse,
                    userName
                )
            ).catch(error => {
                console.error('Error getting sentence feedback:', error);
                setResponseSnackbarMessage(`Fehler beim Analysieren der Antwort.`);
                setOpenResponseSnackbar(true);
            }).finally(() => {
                setRecordingState(prev => ({ ...prev, isProcessing: false }));
            });
        }
    }, [
        voiceResponse,
        lastProcessedResponse,
        recordingState.isProcessing,
        filteredVocabPairs,
        vocabIndex,
        language.language,
        userName,
        getResponse
    ]);

    // Start recording
    const startRecording = useCallback(async () => {
        if (recordingState.isRecording) return;
    
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            recorder.current = new MediaRecorder(stream);
            recorder.current.start();
            
            recorder.current.ondataavailable = e => {
                const file = new File([e.data], "audio.webm", { type: "audio/webm" });
                setAudioFile(file);
            };
            
            setRecordingState(prev => ({
                ...prev,
                isRecording: true
            }));
        } catch (error) {
            console.error("Error starting recording:", error);
            setResponseSnackbarMessage("Fehler beim Starten der Aufnahme");
            setOpenResponseSnackbar(true);
        }
    }, [recordingState.isRecording]);

    // Reset state
    const resetState = useCallback(() => {
        setShowAiResponse(false);
        setIsBlurred(true);
        setIsAdditionalInfoBlurred(true);
        setCurrentStreamResponse('');
        setIsResponseReady(false);
        setRecordingState({
            isRecording: false,
            isProcessing: false
        });
        setAudioFile(null);
        setLastProcessedResponse(null);
        lastVoiceResponseRef.current = null;
        
        if (processingTimeoutRef.current) {
            clearTimeout(processingTimeoutRef.current);
        }

        if (recorder.current && recorder.current.state === 'recording') {
            recorder.current.stop();
            recorder.current = null;
        }
    }, []);

    // Button handlers
    const handleButtonClick = () => {
        if (recordingState.isRecording) {
            if (recorder.current) {
                recorder.current.stop();
            }
            setRecordingState(prev => ({
                ...prev,
                isRecording: false
            }));
            setShowAiResponse(true);
        } else {
            startRecording();
            resetState();
        }
    };

    const handleKnownFilterChange = (event) => {
        setKnownFilter(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleWordSelect = (event) => {
        setSelectedWord(event.target.value);
        const index = filteredVocabPairs.findIndex(pair => pair.german === event.target.value);
        if (index !== -1) {
            setVocabIndex(index);
            resetState();
        }
    };

    const toggleBlur = () => {
        setIsBlurred(!isBlurred);
    };

    const toggleAdditionalInfoBlur = () => {
        setIsAdditionalInfoBlurred(!isAdditionalInfoBlurred);
    };

    const handleSwitchChange = (event) => {
        setIsPlayTextEnabled(event.target.checked);
    };

    const handleCloseResponseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenResponseSnackbar(false);
    };

    if (!language) {
        return <LinearProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">
            Fehler beim Laden der Vokabelliste: {error.message}
        </Typography>;
    }

    if (!vocabPairs) {
        return <LinearProgress />;
    }

    return (
        <Container>
            <Box mt={4} display="flex" flexDirection="column" alignItems="center">
                <FormControlLabel
                    control={<Switch checked={isPlayTextEnabled} onChange={handleSwitchChange} />}
                    label={isPlayTextEnabled ? <VolumeUpIcon /> : <VolumeOffIcon />}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Nach Bekanntheitsgrad filtern</InputLabel>
                            <Select value={knownFilter} onChange={handleKnownFilterChange}>
                                <MenuItem value="all">Alle</MenuItem>
                                <MenuItem value="0">Noch nie gewusst</MenuItem>
                                <MenuItem value="1">Einmal gewusst</MenuItem>
                                <MenuItem value="2">Zweimal gewusst</MenuItem>
                                <MenuItem value="3">Dreimal oder öfter gewusst</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            value={searchTerm}
                            onChange={handleSearchChange}
                            label="Wörter und Tags suchen"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TagFilter 
                            vocabs={vocabPairs} 
                            onFilterChange={setTagFilters}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Wort auswählen</InputLabel>
                            <Select value={selectedWord} onChange={handleWordSelect}>
                                {filteredVocabPairs.map((pair, index) => (
                                    <MenuItem key={index} value={pair.german}>
                                        {pair.german} - {pair.foreign}
                                        {pair.attributes && Object.keys(pair.attributes).length > 0 && (
                                            <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                                                ({Object.entries(pair.attributes)
                                                    .map(([key, value]) => `${key}: ${value}`)
                                                    .join(', ')})
                                            </Typography>
                                        )}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {filteredVocabPairs[vocabIndex] && (
                            <Card elevation={2}>
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{ fontWeight: 'medium', mb: 1 }}>
                                        Spreche einen Satz und verwende das Trainingswort: <strong>{filteredVocabPairs[vocabIndex].german}</strong>
                                    </Typography>
                                    <Typography 
                                        variant="body1" 
                                        sx={{ 
                                            mb: 1, 
                                            cursor: 'pointer',
                                            filter: isBlurred ? 'blur(5px)' : 'none',
                                            transition: 'filter 0.3s ease',
                                            '&:hover': {
                                                filter: 'blur(3px)',
                                            },
                                        }}
                                        onClick={toggleBlur}
                                    >
                                        {filteredVocabPairs[vocabIndex].foreign}
                                    </Typography>
                                    {filteredVocabPairs[vocabIndex].additionalInfo && (
                                        <Typography 
                                            variant="body2" 
                                            sx={{ 
                                                mb: 1,
                                                cursor: 'pointer',
                                                filter: isAdditionalInfoBlurred ? 'blur(5px)' : 'none',
                                                transition: 'filter 0.3s ease',
                                                '&:hover': {
                                                    filter: 'blur(3px)',
                                                },
                                            }}
                                            onClick={toggleAdditionalInfoBlur}
                                        >
                                            {filteredVocabPairs[vocabIndex].additionalInfo}
                                        </Typography>
                                    )}
                                    {filteredVocabPairs[vocabIndex].attributes && Object.keys(filteredVocabPairs[vocabIndex].attributes).length > 0 && (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
                                            {Object.entries(filteredVocabPairs[vocabIndex].attributes).map(([key, value]) => (
                                                <Chip
                                                    key={key}
                                                    label={`${key}: ${value}`}
                                                    size="small"
                                                    variant="outlined"
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <Chip
                                            label={`${filteredVocabPairs[vocabIndex].knownCounter} mal gewusst`}
                                            size="small"
                                            sx={{ mr: 1 }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleButtonClick}
                                            startIcon={recordingState.isRecording ? <StopIcon /> : <MicIcon />}
                                            disabled={recordingState.isProcessing}
                                        >
                                            {recordingState.isRecording ? 'Aufnahme stoppen' : 'Aufnahme starten'}
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                </Grid>

                {showAiResponse && voiceResponse && voiceResponse === lastVoiceResponseRef.current && (
                    <Box mt={2} mb={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1" style={{ marginBottom: '10px' }}>
                                    Deine Antwort: {voiceResponse}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )}

                {currentStreamResponse && (
                    <Box mt={2} mb={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1" component="div">
                                    KI Trainer (streaming): 
                                    <ReactMarkdown>{currentStreamResponse}</ReactMarkdown>
                                    {isStreaming && (
                                        <Box display="flex" alignItems="center" mt={1}>
                                            <CircularProgress size={20} style={{ marginRight: '10px' }} />
                                            Audio wird gestreamt...
                                        </Box>
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )}

                {isResponseReady && response && (
                    <Box mt={2} mb={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1" component="div">
                                    KI Trainer: 
                                    <ReactMarkdown>{response}</ReactMarkdown>
                                    {isStreaming && (
                                        <Box display="flex" alignItems="center" mt={1}>
                                            <CircularProgress size={20} style={{ marginRight: '10px' }} />
                                            Audio wird gestreamt...
                                        </Box>
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </Box>

            <Snackbar 
                open={openResponseSnackbar} 
                autoHideDuration={6000} 
                onClose={handleCloseResponseSnackbar}
            >
                <MuiAlert 
                    onClose={handleCloseResponseSnackbar} 
                    severity="info" 
                    sx={{ width: '100%' }}
                >
                    {responseSnackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Container>
    );
}