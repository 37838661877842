import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { RecordingProvider } from './contexts/RecordingContext';
import { usageTrackingService } from './services/usageTrackingService';
import { CurrentUserProvider } from './contexts/CurrentUserContext';
import { Provider, useSelector } from 'react-redux';
import store from './redux/store';
import { UserAuthListener } from '../src/components/UserAuthListener';
import { CircularProgress, Box, Container, Button, Paper } from '@mui/material';
import { auth } from './firebase/config';
import { ArrowLeft } from 'lucide-react';

// components
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Profile from './components/ProfilePage';
import Footer from './components/Footer';
import SubscriptionManager from './components/SubscriptionManager';
import SubscriptionSection from './components/SubscriptionSection';
import SubscriptionCancellation from './pages/SubscriptionCancellation';

// Legal Pages
import { AGBPage, DatenschutzPage, ImpressumPage } from './components/LegalPages';

// Custom hook for navigation
function useCustomNavigation() {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return navigateTo;
}

// Payment Return Wrapper Component
const PaymentReturnWrapper = () => {
  const navigate = useNavigate();
  
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Button
          startIcon={<ArrowLeft />}
          onClick={() => navigate('/profile')}
          sx={{ mb: 4 }}
        >
          Zurück zum Profil
        </Button>
        <Paper sx={{ p: 0 }}>
          <SubscriptionSection />
        </Paper>
      </Box>
    </Container>
  );
};

// Wrap each component with the custom navigation
const withCustomNavigation = (WrappedComponent) => {
  return (props) => {
    const navigateTo = useCustomNavigation();
    return <WrappedComponent {...props} navigateTo={navigateTo} />;
  };
};

const WrappedHome = withCustomNavigation(Home);
const WrappedLogin = withCustomNavigation(Login);
const WrappedSignup = withCustomNavigation(Signup);
const WrappedProfile = withCustomNavigation(Profile);
const WrappedNavbar = withCustomNavigation(Navbar);
const WrappedSubscriptionManager = withCustomNavigation(SubscriptionManager);
const WrappedPaymentReturn = withCustomNavigation(PaymentReturnWrapper);
const WrappedSubscriptionCancellation = withCustomNavigation(SubscriptionCancellation);

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const currentUser = useSelector(state => state.user.currentUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!currentUser || !currentUser.emailVerified) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  useEffect(() => {
    usageTrackingService.startTracking();
    return () => usageTrackingService.stopTracking();
  }, []);

  return (
    <Provider store={store}>
      <UserAuthListener>
        <Router>
          <div className="App flex flex-col min-h-screen">
            <CurrentUserProvider>
              <WrappedNavbar />
            </CurrentUserProvider>
            <div className="flex-grow">
              <Routes>
                <Route path="/" element={
                  <RecordingProvider>
                    <WrappedHome />
                  </RecordingProvider>
                } />
                <Route path="/signup" element={<WrappedSignup />} />
                <Route path="/login" element={<WrappedLogin />} />
                <Route path="/profile" element={
                  <ProtectedRoute>
                    <WrappedProfile />
                  </ProtectedRoute>
                } />
                {/* Subscription Routes */}
                <Route path="/subscription" element={
                  <ProtectedRoute>
                    <WrappedSubscriptionManager />
                  </ProtectedRoute>
                } />
                <Route path="/subscription/cancel" element={
                  <ProtectedRoute>
                    <WrappedSubscriptionCancellation />
                  </ProtectedRoute>
                } />
                <Route path="/payment-return" element={
                  <ProtectedRoute>
                    <WrappedPaymentReturn />
                  </ProtectedRoute>
                } />
                {/* Legal Pages Routes */}
                <Route path="/agb" element={<AGBPage />} />
                <Route path="/datenschutz" element={<DatenschutzPage />} />
                <Route path="/impressum" element={<ImpressumPage />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </UserAuthListener>
    </Provider>
  );
}

export default App;