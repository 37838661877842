import { createSlice, createSelector } from '@reduxjs/toolkit';
import { db, auth } from '../firebase/config';

const initialState = {
  currentUser: null,
  subscription: {
    firestoreStatus: 'inactive',
    verifiedStatus: 'inactive',
    mollieStatus: null,
    plan: null,
    pendingPayment: null,
    lastUpdated: null,
    features: {
      maxVocabs: 50,
      aiTranslations: false,
      grammarCheck: false,
      speechRecognition: false
    }
  },
  loading: false,
  error: null
};

// Helper function für die Features
const determineFeatures = (subscription) => {
  // Wenn gekündigt aber noch aktiv
  if (subscription?.subscription?.cancelledAt && subscription?.subscription?.nextPaymentDate) {
    const nextPayment = new Date(subscription.subscription.nextPaymentDate);
    if (nextPayment > new Date()) {
      return {
        maxVocabs: 999999,
        aiTranslations: true,
        grammarCheck: true,
        speechRecognition: true
      };
    }
  }

  // Wenn aktiv (entweder Promo oder Standard Subscription ist aktiv)
  if ((subscription?.subscription?.promoDetails?.status === 'active' || 
       subscription?.subscription?.standardDetails?.status === 'active') && 
       !subscription?.subscription?.cancelledAt) {
    return {
      maxVocabs: 999999,
      aiTranslations: true,
      grammarCheck: true,
      speechRecognition: true
    };
  }

  // Standard Features
  return {
    maxVocabs: 50,
    aiTranslations: false,
    grammarCheck: false,
    speechRecognition: false
  };
};

// Helper function für das nächste Zahlungsdatum
const getNextPaymentDate = (subscription) => {
  if (subscription?.currentPhase === 'promo' && subscription.promoPhase) {
    const startDate = new Date(subscription.startDate);
    const remainingMonths = subscription.promoPhase.remainingMonths;
    const elapsedMonths = subscription.promoPhase.totalMonths - remainingMonths;
    
    if (remainingMonths > 0) {
      const nextDate = new Date(startDate);
      nextDate.setMonth(nextDate.getMonth() + elapsedMonths);
      return nextDate.toISOString();
    }
  }
  return subscription?.standardDetails?.nextPaymentDate;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      if (action.payload) {
        const { 
          uid,
          email,
          emailVerified,
          displayName,
          phoneNumber,
          photoURL
        } = action.payload;
        
        state.currentUser = {
          uid,
          email,
          emailVerified,
          displayName,
          phoneNumber,
          photoURL
        };
      } else {
        state.currentUser = null;
        state.subscription = initialState.subscription;
      }
    },
    setSubscriptionData: (state, action) => {
      console.log('Setting subscription data:', action.payload);
      
      // Übernehme die Status-Flags
      state.subscription.firestoreStatus = action.payload.subscriptionStatus;
      state.subscription.verifiedStatus = action.payload.subscriptionStatus;
      state.subscription.mollieStatus = 
        action.payload.subscription?.promoDetails?.status || 
        action.payload.subscription?.standardDetails?.status;
      
      // Übernehme die Plan-Details
      state.subscription.plan = action.payload.subscription?.plan || null;
      state.subscription.pendingPayment = action.payload.pendingPayment;
      state.subscription.lastUpdated = action.payload.lastUpdated;

      // Übernehme die Subscription-Details
      if (action.payload.subscription) {
        state.subscription = {
          ...state.subscription,
          ...action.payload.subscription,
          // Behalte die lokalen Flags
          firestoreStatus: state.subscription.firestoreStatus,
          verifiedStatus: state.subscription.verifiedStatus,
          mollieStatus: state.subscription.mollieStatus
        };
      }

      // Aktualisiere Features basierend auf den neuen Daten
      state.subscription.features = determineFeatures({
        subscription: action.payload.subscription
      });

      console.log('Updated subscription state:', state.subscription);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

// Base selectors
export const selectCurrentUser = (state) => state.user.currentUser;
export const selectSubscription = (state) => state.user.subscription;
export const selectLoading = (state) => state.user.loading;
export const selectError = (state) => state.user.error;

// Memoized selectors
export const selectSubscriptionStatus = createSelector(
  [selectSubscription],
  (subscription) => {
    console.log('Subscription state:', subscription);

    // Prüfe ob Promo oder Standard Subscription aktiv ist
    const hasActivePromo = subscription?.subscription?.promoDetails?.status === 'active';
    const hasActiveStandard = subscription?.subscription?.standardDetails?.status === 'active';
    
    // Wenn irgendeine Subscription aktiv ist
    if (hasActivePromo || hasActiveStandard) {
      if (subscription?.subscription?.cancelledAt) {
        return 'active-until';
      }
      return 'active';
    }

    // Wenn eine Zahlung in Bearbeitung ist
    if (subscription?.pendingPayment && !subscription.pendingPayment.status) {
      const paymentDate = new Date(subscription.pendingPayment.createdAt);
      const timeDiff = Date.now() - paymentDate.getTime();
      const minutesDiff = Math.floor(timeDiff / 1000 / 60);
      
      if (minutesDiff < 30) {
        return 'pending';
      }
    }

    // Wenn Zahlung erfolgreich
    if (subscription?.pendingPayment?.status === 'paid') {
      return 'active';
    }

    return 'inactive';
  }
);

export const selectSubscriptionDetails = createSelector(
  [selectSubscription],
  (subscription) => {
    console.log('selectSubscriptionDetails input:', subscription);

    // Wenn keine aktive Subscription
    if (!subscription?.plan || 
        (!subscription?.promoDetails?.status === 'active' && 
         !subscription?.standardDetails?.status === 'active')) {
      return {
        status: 'inactive',
        plan: null,
        promoSubscription: null,
        standardSubscription: null,
        startDate: null,
        nextPaymentDate: null,
        validUntil: null,
        cancelledAt: null,
        active: false,
        cancelled: false,
        price: 0,
        features: subscription?.features || initialState.subscription.features
      };
    }

    const promoDetails = subscription.promoDetails;
    const standardDetails = subscription.standardDetails;
    
    // Determine current phase and next payment
    const currentPhase = promoDetails?.status === 'active' ? 'promo' : 'standard';
    const nextPaymentDate = getNextPaymentDate({
      currentPhase,
      promoPhase: subscription.promoPhase,
      standardDetails,
      startDate: promoDetails?.startDate || standardDetails?.startDate
    });

    // Create new promoSubscription object with all needed properties
    const promoSubscription = promoDetails ? {
      ...promoDetails,
      remainingPayments: subscription.promoPhase?.remainingMonths || 0,
      totalPayments: subscription.promoPhase?.totalMonths || 3
    } : null;

    // Determine valid until date
    const validUntil = subscription.cancelledAt ? 
      (promoDetails?.nextPaymentDate || standardDetails?.nextPaymentDate) : 
      null;

    // Determine active status
    const isActive = (promoDetails?.status === 'active' || standardDetails?.status === 'active');

    return {
      status: isActive ? (subscription.cancelledAt ? 'active-until' : 'active') : 'inactive',
      plan: subscription.plan,
      currentPhase,
      promoSubscription,  // Verwende das neue Objekt
      standardSubscription: standardDetails,
      startDate: promoDetails?.startDate || standardDetails?.startDate,
      nextPaymentDate,
      validUntil,
      cancelledAt: subscription.cancelledAt,
      active: isActive,
      cancelled: !!subscription.cancelledAt,
      price: subscription.plan === 'yearly' ? 79.99 : 7.99,
      features: subscription.features
    };
  }
);

export const selectIsPremium = createSelector(
  [selectSubscriptionDetails],
  (details) => details?.active || false
);

export const selectFeatures = createSelector(
  [selectSubscription],
  (subscription) => subscription?.features || initialState.subscription.features
);

// Actions
export const { 
  setCurrentUser, 
  setSubscriptionData, 
  setLoading, 
  setError 
} = userSlice.actions;

// Thunks
export const fetchSubscriptionStatus = () => async (dispatch, getState) => {
  const { currentUser } = getState().user;
  if (!currentUser) return;

  dispatch(setLoading(true));
  try {
    const firebaseUser = auth.currentUser;
    if (!firebaseUser) {
      throw new Error('No Firebase user found');
    }

    const token = await firebaseUser.getIdToken(true);
    const response = await fetch('https://brainwizzr-backend-41f7251d9520.herokuapp.com/api/subscriptions/status', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch subscription status');
    }

    const data = await response.json();
    console.log('Received subscription data:', data);
    
    dispatch(setSubscriptionData(data));
    
    const updatedState = getState();
    console.log('Updated subscription state:', updatedState.user.subscription);
  } catch (error) {
    console.error('Error fetching subscription:', error);
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

// Auto-Refresh Setup
export const setupSubscriptionAutoRefresh = () => (dispatch, getState) => {
  const checkAndRefresh = () => {
    const state = getState();
    const subscription = state.user.subscription;
    
    if (subscription?.subscription?.nextPaymentDate) {
      const nextPayment = new Date(subscription.subscription.nextPaymentDate);
      if (nextPayment <= new Date()) {
        dispatch(fetchSubscriptionStatus());
      }
    }
  };

  // Check every 5 minutes
  setInterval(checkAndRefresh, 5 * 60 * 1000);
  
  // Initial check
  checkAndRefresh();
};

export default userSlice.reducer;