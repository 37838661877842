import { auth } from '../firebase/config';
import { db } from '../firebase/config';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';

export function useAddVocab() {
  const addVocab = async (foreign, german, knownCounter, languageId, nextTraining, additionalInfo, attributes = {}) => {
    const userId = auth.currentUser?.uid;

    await addDoc(collection(db, 'vocabList'), { 
      foreign: foreign,
      german: german,
      knownCounter: Number(knownCounter),
      languageId: languageId,
      nextTraining: nextTraining,
      userId: userId,
      additionalInfo: additionalInfo || '',
      attributes: attributes // Neues Feld für benutzerdefinierte Attribute
    });
  };

  const checkVocabExists = async (foreign, languageId) => {
    const userId = auth.currentUser?.uid;
    
    const vocabQuery = query(
      collection(db, 'vocabList'),
      where('foreign', '==', foreign),
      where('languageId', '==', languageId),
      where('userId', '==', userId)
    );

    const querySnapshot = await getDocs(vocabQuery);
    
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return {
        id: doc.id,
        ...doc.data()
      };
    }

    return null;
  };

  // Neue Hilfsfunktion zum Abrufen von Attributvorschlägen
  const getAttributeSuggestions = async (languageId, attributeKey, searchQuery) => {
    const userId = auth.currentUser?.uid;
    
    const vocabQuery = query(
      collection(db, 'vocabList'),
      where('languageId', '==', languageId),
      where('userId', '==', userId)
    );

    const querySnapshot = await getDocs(vocabQuery);
    
    if (!attributeKey) {
      // Suche nach Attributnamen
      const keys = new Set();
      querySnapshot.forEach(doc => {
        const vocab = doc.data();
        if (vocab.attributes) {
          Object.keys(vocab.attributes).forEach(key => {
            if (key.toLowerCase().includes(searchQuery.toLowerCase())) {
              keys.add(key);
            }
          });
        }
      });
      return { 
        keys: Array.from(keys) 
      };
    } else {
      // Suche nach Attributwerten für einen bestimmten Key
      const values = new Set();
      querySnapshot.forEach(doc => {
        const vocab = doc.data();
        if (vocab.attributes && vocab.attributes[attributeKey]) {
          const value = vocab.attributes[attributeKey];
          if (value.toLowerCase().includes(searchQuery.toLowerCase())) {
            values.add(value);
          }
        }
      });
      return { 
        values: Array.from(values) 
      };
    }
};

  return {
    addVocab,
    checkVocabExists,
    getAttributeSuggestions
  };
}