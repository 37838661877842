import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Button,
  Divider,
} from '@mui/material';
import {
  Star,
  Calendar,
  Shield,
  CheckCircle2,
  ChevronDown,
  Zap,
  Timer,
  Info,
  CreditCard,
  DollarSign,
} from 'lucide-react';
import { 
  selectSubscriptionDetails, 
  selectLoading,
  fetchSubscriptionStatus 
} from '../redux/userSlice';

const SubscriptionSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = useSelector(selectSubscriptionDetails);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(fetchSubscriptionStatus());
  }, [dispatch]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  };

  const formatPrice = (price) => {
    return Number(price).toFixed(2).replace('.', ',') + ' €';
  };

  if (loading) {
    return (
      <Paper className="p-6 mt-6">
        <Box className="flex justify-center items-center p-4">
          <Typography>Lade Subscription-Status...</Typography>
        </Box>
      </Paper>
    );
  }

  // Basis-Layout für nicht-Premium Nutzer
  if (!details?.active) {
    return (
      <Paper className="p-6 mt-6">
        <Box className="flex justify-between items-center mb-6">
          <Typography variant="h5" className="flex items-center gap-2 text-2xl">
            <Star className="w-6 h-6" />
            Premium Status
          </Typography>
          <Chip 
            icon={<Info className="w-4 h-4" />}
            label="Kein aktives Abo"
            color="default"
            className="font-medium text-base"
          />
        </Box>

        <Alert 
          severity="info" 
          className="mb-6"
          icon={<Info className="w-5 h-5" />}
        >
          <Typography variant="subtitle1" className="font-medium">
            Kein Premium-Abo
          </Typography>
          <Typography className="text-gray-600">
            Upgrade auf Premium, um von allen Vorteilen zu profitieren!
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            className="mt-4"
            onClick={() => navigate('/subscription')}
            sx={{
              '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Jetzt upgraden
          </Button>        </Alert>

        <Accordion className="mb-6 shadow-none">
          <AccordionSummary 
            expandIcon={<ChevronDown />}
            className="px-0 hover:bg-transparent"
          >
            <Box className="flex items-center gap-2">
              <Zap className="w-5 h-5" />
              <Typography className="text-xl">
                Premium-Vorteile
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className="px-0">
            <List>
              {[
                "Unbegrenzte Vokabeln",
                "KI-Übersetzungen",
                "Aussprachetraining",
                "Fortgeschrittene Lernstatistiken",
                "Offline-Zugang",
                "Prioritäts-Support"
              ].map((feature, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircle2 className="w-4 h-4 text-gray-400" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={feature} 
                    primaryTypographyProps={{ 
                      className: "text-gray-500"
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }

  // Premium Nutzer Layout
  return (
    <Paper className="p-6 mt-6">
      <Box className="flex justify-between items-center mb-6">
        <Typography variant="h5" className="flex items-center gap-2 text-2xl">
          <Star className="w-6 h-6" />
          Premium Status
        </Typography>
        <Chip 
          icon={details.cancelled ? <Timer className="w-4 h-4" /> : <CheckCircle2 className="w-4 h-4" />}
          label={details.cancelled ? "Premium (gekündigt)" : "Premium Aktiv"}
          color={details.cancelled ? "warning" : "success"}
          className="font-medium text-base"
        />
      </Box>

      <List className="mb-6">
        <ListItem className="mb-4">
          <ListItemIcon>
            <Star className="w-5 h-5" />
          </ListItemIcon>
          <ListItemText 
            primary="Aktiver Plan"
            secondary={`${details.plan === 'yearly' ? 'Jahresabo' : 'Monatsabo'}`}
            primaryTypographyProps={{ className: "text-xl mb-1" }}
            secondaryTypographyProps={{ className: "text-gray-600" }}
          />
        </ListItem>

        {details.promoSubscription && (
  <ListItem className="mb-4">
    <ListItemIcon>
      <DollarSign className="w-5 h-5" />
    </ListItemIcon>
    <ListItemText 
  primary="Promo-Phase"
  secondary={
    <>
      {`Aktueller Preis: ${formatPrice(details.promoSubscription.amount.value)}/Monat`}<br />
      {`Laufzeit: ${formatDate(details.promoSubscription.startDate)} - ${formatDate(details.nextPaymentDate)}`}<br />
      {`Promo-Zahlungen: ${details.promoSubscription.timesRemaining} weitere von insgesamt ${details.promoSubscription.times}`}<br />
      {`Nächste Zahlung: ${formatDate(details.promoSubscription.nextPaymentDate)}`}
    </>
  }
  primaryTypographyProps={{ className: "text-xl mb-1" }}
  secondaryTypographyProps={{ 
    className: "text-gray-600"
  }}
/>
  </ListItem>
)}

        {details.standardSubscription && (
          <ListItem className="mb-4">
            <ListItemIcon>
              <CreditCard className="w-5 h-5" />
            </ListItemIcon>
            <ListItemText 
              primary="Standard-Abo"
              secondary={
                <>
                  {`Preis: ${formatPrice(details.standardSubscription.amount.value)}/${details.plan === 'yearly' ? 'Jahr' : 'Monat'}\n`}
                  {details.currentPhase === 'promo' 
                    ? `Startet nach Promo-Phase am: ${formatDate(details.standardSubscription.startDate)}\n`
                    : `Aktiv seit: ${formatDate(details.standardSubscription.startDate)}\n`}
                  {details.currentPhase !== 'promo' && 
                    `Nächste Zahlung: ${formatDate(details.standardSubscription.nextPaymentDate)}`}
                </>
              }
              primaryTypographyProps={{ className: "text-xl mb-1" }}
              secondaryTypographyProps={{ 
                className: "text-gray-600 whitespace-pre-line"
              }}
            />
          </ListItem>
        )}
        
        <ListItem className="mb-4">
          <ListItemIcon>
            <Shield className="w-5 h-5" />
          </ListItemIcon>
          <ListItemText 
            primary="Zahlungssicherheit"
            secondary="Sichere Zahlungsabwicklung über Mollie Payment Services"
            primaryTypographyProps={{ className: "text-xl mb-1" }}
            secondaryTypographyProps={{ className: "text-gray-600" }}
          />
        </ListItem>
      </List>

      {details.cancelled && (
        <Alert 
          severity="info" 
          className="mb-6"
          icon={<Info className="w-5 h-5" />}
        >
          <Typography variant="subtitle1" className="font-medium">
            Abo gekündigt
          </Typography>
          <Typography className="text-gray-600">
            Dein Premium-Abo läuft noch bis zum {formatDate(details.validUntil)}. 
            Bis dahin kannst du alle Premium-Features uneingeschränkt nutzen.
          </Typography>
        </Alert>
      )}

      <Accordion className="mb-6 shadow-none">
        <AccordionSummary 
          expandIcon={<ChevronDown />}
          className="px-0 hover:bg-transparent"
        >
          <Box className="flex items-center gap-2">
            <Zap className="w-5 h-5" />
            <Typography className="text-xl">
              Deine Premium-Vorteile
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="px-0">
          <List>
            {[
              "Unbegrenzte Vokabeln",
              "KI-Übersetzungen",
              "Aussprachetraining",
              "Fortgeschrittene Lernstatistiken",
              "Offline-Zugang",
              "Prioritäts-Support"
            ].map((feature, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CheckCircle2 className="w-4 h-4 text-green-500" />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default SubscriptionSection;