import React, { useState, useEffect } from 'react';
import {
    Box,
    Chip,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';

export function TagFilter({ vocabs, onFilterChange }) {
    const [expanded, setExpanded] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    
    // Extrahiere alle unique Tags aus den Vokabeln
    const allTags = vocabs.reduce((tags, vocab) => {
        if (vocab.attributes) {
            Object.entries(vocab.attributes).forEach(([key, value]) => {
                if (!tags[key]) {
                    tags[key] = new Set();
                }
                tags[key].add(value);
            });
        }
        return tags;
    }, {});

    // Wenn sich die Filter ändern, informiere den Parent
    useEffect(() => {
        onFilterChange(selectedFilters);
    }, [selectedFilters, onFilterChange]);

    const handleFilterChange = (tagKey, tagValue, checked) => {
        setSelectedFilters(prev => ({
            ...prev,
            [tagKey]: {
                ...(prev[tagKey] || {}),
                [tagValue]: checked
            }
        }));
    };

    const handleClearFilters = () => {
        setSelectedFilters({});
    };

    // Zähle aktive Filter
    const activeFilterCount = Object.values(selectedFilters).reduce(
        (count, values) => count + Object.values(values).filter(Boolean).length,
        0
    );

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center" gap={1}>
                    <FilterListIcon />
                    <Typography>
                        Tags filtern 
                        {activeFilterCount > 0 && ` (${activeFilterCount} aktiv)`}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" flexDirection="column" gap={2}>
                    {Object.entries(allTags).map(([key, values]) => (
                        <Box key={key}>
                            <Typography variant="subtitle2" gutterBottom>
                                {key}:
                            </Typography>
                            <FormGroup row>
                                {Array.from(values).map(value => (
                                    <FormControlLabel
                                        key={`${key}-${value}`}
                                        control={
                                            <Checkbox
                                                checked={selectedFilters[key]?.[value] || false}
                                                onChange={(e) => handleFilterChange(key, value, e.target.checked)}
                                                size="small"
                                            />
                                        }
                                        label={value}
                                    />
                                ))}
                            </FormGroup>
                        </Box>
                    ))}
                    {activeFilterCount > 0 && (
                        <Box display="flex" justifyContent="flex-end">
                            <Chip
                                label="Filter zurücksetzen"
                                onClick={handleClearFilters}
                                size="small"
                            />
                        </Box>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}