import { DEFAULT_USER_FIELDS } from '../constants/defaultFields';

export function ensureDefaultFields(userData, defaults = DEFAULT_USER_FIELDS) {
  const result = { ...userData };
  
  for (const [key, value] of Object.entries(defaults)) {
    if (!(key in result)) {
      console.log(`Adding missing field: ${key}`);
      result[key] = value;
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      result[key] = ensureDefaultFields(result[key], value);
    }
  }
  
  return result;
}

export function validateUserFields(userData) {
  const errors = [];
  const warnings = [];

  if (!userData.uid) errors.push('Missing required field: uid');
  if (!userData.email) errors.push('Missing required field: email');
  if (typeof userData.verified !== 'boolean') warnings.push('verified field should be boolean');

  if (userData.subscription) {
    if (!['active', 'inactive', 'cancelled'].includes(userData.subscription.status)) {
      warnings.push('Invalid subscription status');
    }
    if (typeof userData.subscription.features.maxVocabs !== 'number') {
      warnings.push('maxVocabs should be a number');
    }
  }

  return {
    isValid: errors.length === 0,
    errors,
    warnings,
    hasWarnings: warnings.length > 0
  };
}