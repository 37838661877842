import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { useVocabList } from '../hooks/useVocabList';
import { useSelector } from 'react-redux';
import { useDeleteVocab } from '../hooks/useDeleteVocab';
import { useChangeVocab } from '../hooks/useChangeVocab';
import { BulkAttributeEditor } from './BulkAttributeEditor';
import { AttributeInput } from './AttributeInput';
import {
    Card,
    CardContent,
    Typography,
    LinearProgress,
    IconButton,
    TextField,
    Box,
    Grid,
    Chip,
    Checkbox,
    Button,
    FormGroup,
    FormControlLabel,
    Collapse,
    Tooltip,
    Paper,
    Snackbar,
    Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function VocabList() {
    const [user] = useAuthState(auth);
    const userId = user ? user.uid : null;
    const language = useSelector(state => state.language);
    const { vocabPairs, error } = useVocabList(userId, language);
    const { deleteVocab } = useDeleteVocab();
    const { changeVocab, bulkUpdateAttributes } = useChangeVocab();
    const [snackbarMessage, setSnackbarMessage] = useState(null);

    const [editingId, setEditingId] = useState(null);
    const [german, setGerman] = useState('');
    const [foreign, setForeign] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [attributes, setAttributes] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedVocabs, setSelectedVocabs] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [attributeFilters, setAttributeFilters] = useState({});

    // Sammelt alle unique Attribute aus allen Vokabeln
    const allAttributes = vocabPairs?.reduce((attrs, vocab) => {
        if (vocab.attributes) {
            Object.entries(vocab.attributes).forEach(([key, value]) => {
                if (!attrs[key]) {
                    attrs[key] = new Set();
                }
                attrs[key].add(value);
            });
        }
        return attrs;
    }, {});

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString('de-DE', { 
            day: 'numeric', 
            month: 'numeric', 
            year: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit' 
        });
    };

    const handleEdit = (vocabPair) => {
        setEditingId(vocabPair.id);
        setGerman(vocabPair.german);
        setForeign(vocabPair.foreign);
        setAdditionalInfo(vocabPair.additionalInfo || '');
        setAttributes(vocabPair.attributes || {});
    };

    const handleSave = async (vocabPair) => {
        await changeVocab(
            vocabPair.id,
            foreign,
            german,
            vocabPair.knownCounter,
            vocabPair.languageId,
            vocabPair.nextTraining,
            additionalInfo,
            attributes
        );
        setEditingId(null);
        resetEditForm();
    };

    const resetEditForm = () => {
        setEditingId(null);
        setGerman('');
        setForeign('');
        setAdditionalInfo('');
        setAttributes({});
    };

    const handleCancel = () => {
        resetEditForm();
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedVocabs(filteredVocabPairs.map(vocab => vocab.id));
        } else {
            setSelectedVocabs([]);
        }
    };

    const handleSelectVocab = (vocabId) => {
        setSelectedVocabs(prev => 
            prev.includes(vocabId)
                ? prev.filter(id => id !== vocabId)
                : [...prev, vocabId]
        );
    };

    const handleBulkAttributesApply = async ({ attributesToAdd, attributesToRemove }) => {
        try {
          await bulkUpdateAttributes(selectedVocabs, attributesToAdd, attributesToRemove);
          // Erfolgsmeldung anzeigen
          setSnackbarMessage({
            type: 'success',
            message: `Attribute erfolgreich für ${selectedVocabs.length} Vokabeln aktualisiert`
          });
        } catch (error) {
          console.error('Fehler beim Aktualisieren der Attribute:', error);
          // Fehlermeldung anzeigen
          setSnackbarMessage({
            type: 'error',
            message: 'Fehler beim Aktualisieren der Attribute. Bitte versuchen Sie es erneut.'
          });
        } finally {
          setSelectedVocabs([]);
        }
      };

    const handleAttributeFilterChange = (key, value, checked) => {
        setAttributeFilters(prev => ({
            ...prev,
            [key]: {
                ...(prev[key] || {}),
                [value]: checked
            }
        }));
    };

    // Filterfunktion
// Filterfunktion
const filteredVocabPairs = vocabPairs?.filter(vocabPair => {
    // Textsuche - aufteilen in einzelne Suchbegriffe
    const searchTerms = searchTerm.toLowerCase().trim().split(/\s+/);
    
    // Prüfe, ob alle Suchbegriffe irgendwo vorkommen
    const matchesSearch = searchTerms.every(term => {
        // Suche in Basis-Feldern
        const inBasicFields = 
            vocabPair.german.toLowerCase().includes(term) ||
            vocabPair.foreign.toLowerCase().includes(term) ||
            (vocabPair.additionalInfo && 
             vocabPair.additionalInfo.toLowerCase().includes(term));

        // Suche in Attributen
        const inAttributes = vocabPair.attributes && 
            Object.entries(vocabPair.attributes).some(([key, value]) => {
                // Prüfe auf exakte Attribut-Matches (z.B. "Lektion: 3")
                const exactMatch = `${key}: ${value}`.toLowerCase().includes(term);
                // Prüfe auf separate Matches in Key oder Value
                const separateMatch = 
                    key.toLowerCase().includes(term) ||
                    value.toString().toLowerCase().includes(term);
                // Prüfe auf kombinierte Matches (z.B. "Lektion 3")
                const combinedMatch = `${key} ${value}`.toLowerCase().includes(term);
                
                return exactMatch || separateMatch || combinedMatch;
            });

        return inBasicFields || inAttributes;
    });

    // Attributfilter (bestehende Logik)
    const matchesAttributes = Object.entries(attributeFilters).every(([key, values]) => {
        const activeFilters = Object.entries(values)
            .filter(([_, isActive]) => isActive)
            .map(([value]) => value);
        if (activeFilters.length === 0) return true;
        return vocabPair.attributes && 
               vocabPair.attributes[key] && 
               activeFilters.includes(vocabPair.attributes[key]);
    });

    return matchesSearch && matchesAttributes;
}) || [];

    if (!language) {
        return <LinearProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">Error loading vocab list: {error.message}</Typography>;
    }

    if (!vocabPairs) {
        return <LinearProgress />;
    }

    return (
        <div>
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <TextField
                            fullWidth
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            label="Suche (Wörter & Attribute)"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            startIcon={<FilterListIcon />}
                            onClick={() => setShowFilters(!showFilters)}
                            variant={showFilters ? "contained" : "outlined"}
                        >
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Collapse in={showFilters}>
                <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Filter nach Attributen
                    </Typography>
                    {Object.entries(allAttributes || {}).map(([key, values]) => (
                        <Box key={key} sx={{ mb: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>
                                {key}:
                            </Typography>
                            <FormGroup row>
                                {Array.from(values).map(value => (
                                    <FormControlLabel
                                        key={value}
                                        control={
                                            <Checkbox
                                                checked={attributeFilters[key]?.[value] || false}
                                                onChange={(e) => handleAttributeFilterChange(key, value, e.target.checked)}
                                            />
                                        }
                                        label={value}
                                    />
                                ))}
                            </FormGroup>
                        </Box>
                    ))}
                </Paper>
            </Collapse>

            {selectedVocabs.length > 0 && (
                <BulkAttributeEditor
                    languageId={language.id}
                    selectedVocabs={selectedVocabs.map(id => 
                        vocabPairs.find(vocab => vocab.id === id)
                    )}
                    onApply={handleBulkAttributesApply}
                    onCancel={() => setSelectedVocabs([])}
                />
            )}

            <FormControlLabel
                control={
                    <Checkbox
                        checked={selectedVocabs.length === filteredVocabPairs.length && filteredVocabPairs.length > 0}
                        indeterminate={selectedVocabs.length > 0 && selectedVocabs.length < filteredVocabPairs.length}
                        onChange={handleSelectAll}
                    />
                }
                label={`Alle auswählen (${filteredVocabPairs.length})`}
            />

            {filteredVocabPairs.slice().sort((a, b) => a.german.localeCompare(b.german)).map((vocabPair) => (
                <Card key={vocabPair.id} elevation={2} sx={{ mb: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Checkbox
                                    checked={selectedVocabs.includes(vocabPair.id)}
                                    onChange={() => handleSelectVocab(vocabPair.id)}
                                />
                            </Grid>
                            <Grid item xs>
                                {editingId === vocabPair.id ? (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Deutsch"
                                                value={german}
                                                onChange={(e) => setGerman(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label={language.language}
                                                value={foreign}
                                                onChange={(e) => setForeign(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Zusatzinfo"
                                                value={additionalInfo}
                                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ mb: 2 }}>
                                                <AttributeInput
                                                    languageId={language.id}
                                                    initialAttributes={attributes}
                                                    onChange={setAttributes}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                                <IconButton onClick={() => handleSave(vocabPair)} color="primary">
                                                    <SaveIcon />
                                                </IconButton>
                                                <IconButton onClick={handleCancel}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>
                                        <Typography variant="h6" component="div" gutterBottom>
                                            {vocabPair.german} - {vocabPair.foreign}
                                        </Typography>
                                        {vocabPair.additionalInfo && (
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                {vocabPair.additionalInfo}
                                            </Typography>
                                        )}
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
                                            <Chip 
                                                label={`${vocabPair.knownCounter} mal gewusst`}
                                                size="small"
                                                color="primary"
                                                variant="outlined"
                                            />
                                            <Chip
                                                label={`Nächstes Training: ${formatDate(vocabPair.nextTraining)}`}
                                                size="small"
                                                color="secondary"
                                                variant="outlined"
                                            />
                                        </Box>
                                        {vocabPair.attributes && Object.entries(vocabPair.attributes).length > 0 && (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
                                            {Object.entries(vocabPair.attributes).map(([key, value]) => {
                                                const valueLower = value.toLowerCase();
                                                const keyLower = key.toLowerCase();
                                                const label = valueLower.includes(keyLower) ? value : `${key}: ${value}`;
                                                return (
                                                    <Chip
                                                        key={key}
                                                        label={label}
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                );
                                            })}
                                        </Box>
                                    )}                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <IconButton 
                                                onClick={() => handleEdit(vocabPair)}
                                                size="small"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton 
                                                onClick={() => deleteVocab(vocabPair.id)}
                                                size="small"
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
}