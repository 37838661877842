import React, { useState } from 'react';
import { useAddVocab } from '../hooks/useAddVocab';
import useAiTranslation from '../hooks/useAiTranslation';
import { useSelector } from 'react-redux';
import { AttributeInput } from './AttributeInput';
import {
    TextField,
    Button,
    Grid,
    Box,
    Switch,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Popover,
    Typography,
    Paper,
    Collapse
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function VocabInput() {
    const [germanWord, setGermanWord] = useState('');
    const [foreignWord, setForeignWord] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [attributes, setAttributes] = useState({});
    const [showAttributes, setShowAttributes] = useState(false);
    const { addVocab, checkVocabExists } = useAddVocab();
    const language = useSelector(state => state.language);
    const { response, getResponse } = useAiTranslation();
    const [isAiTranslationActive, setIsAiTranslationActive] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [existingWord, setExistingWord] = useState(null);
    const [isThreeColumnMode, setIsThreeColumnMode] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [importedVocabs, setImportedVocabs] = useState([]);
    const [openImportDialog, setOpenImportDialog] = useState(false);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const importInstructions = (
        <Box p={2}>
            <Typography variant="h6" gutterBottom>Massenimport-Anleitung:</Typography>
            <Typography variant="body1" paragraph>
                1. Öffnen Sie Excel und befüllen Sie zwei bis vier Spalten:
                   A: Deutsch, B: Fremdsprache, C: Zusatzinfo (optional), D: Tags (optional)
            </Typography>
            <Typography variant="body1" paragraph>
                2. Tags können in zwei Formaten angegeben werden:
                   - Mit Schlüssel: "Lektion: 2; Buchtitel: English for Dummies"
                   - Als einfache Tags: "Tag1, Tag2, Tag3"
            </Typography>
            <Typography variant="body1" paragraph>
                3. Markieren und kopieren Sie die befüllten Zellen (ohne Überschriften).
            </Typography>
            <Typography variant="body1">
                Beispiele in Excel:
                <br />
                A1: Hund | B1: dog | C1: Haustier | D1: Lektion: 1; Kapitel: Tiere
                <br />
                A2: Katze | B2: cat | | D2: Kapitel: Tiere, Haustiere
            </Typography>
        </Box>
    );

    const handleAdd = async () => {
        const existingVocab = await checkVocabExists(foreignWord, language.id);
        if (existingVocab) {
            setExistingWord(existingVocab);
            setOpenDialog(true);
        } else {
            await addVocabToDatabase();
        }
    };

    const addVocabToDatabase = async () => {
        const nowGMT = new Date().toISOString();
        await addVocab(
            foreignWord,
            germanWord,
            '0',
            language.id,
            nowGMT,
            additionalInfo,
            attributes // Neue Attribute hinzufügen
        );
        setGermanWord('');
        setForeignWord('');
        setAdditionalInfo('');
        setAttributes({});
    };

    const handleConfirmAdd = async () => {
        await addVocabToDatabase();
        setOpenDialog(false);
    };

    const handleCancelAdd = () => {
        setOpenDialog(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAdd();
        }
    };

    const handleAiTranslationChange = (event) => {
        setIsAiTranslationActive(event.target.checked);
    };

    const handleColumnModeChange = (event) => {
        setIsThreeColumnMode(event.target.checked);
    };

    const handleGermanBlur = async () => {
        if (germanWord && isAiTranslationActive) {
            let foreignPrompt, zusatzinfoPrompt;

            if (language.isSubjectTopic) {
                foreignPrompt = `Basierend auf dem Thema "${language.language}" und dem Stichwort/der Frage "${germanWord}", generiere eine prägnante und fachlich korrekte Antwort. Die Antwort sollte direkt und informativ sein, ohne Einleitungen wie 'Die Antwort lautet:'. Beschränke dich auf maximal 2-3 Sätze, es sei denn, eine ausführlichere Erklärung ist unbedingt notwendig.`;
                zusatzinfoPrompt = `Generiere eine prägnante Zusatzinformation für den Begriff "${germanWord}" im Kontext des Sachthemas "${language.language}". 
Berücksichtige dabei beispielsweise folgende Aspekte:
- Definition: Gib eine kurze, präzise Definition.
- Kontext: Erkläre kurz, in welchem Zusammenhang der Begriff relevant ist.
- Wichtige Merkmale: Liste 2-3 Hauptmerkmale oder -eigenschaften auf.
- Verwandte Begriffe: Nenne 1-2 eng verwandte Konzepte oder Begriffe.
- Anwendung/Beispiel: Gib ein kurzes, konkretes Anwendungsbeispiel.

Wähle jedoch nur eines der beispielhaften Aspekte aus, und zwar den Aspekt, der für das entsprechende Sachthema und das konkrete Stichwort am sinnvollsten ist. Formatiere die Antwort kompakt und ohne Einleitung, ähnlich einem Lexikoneintrag. Beschränke dich auf die wesentlichen Informationen und verwende Stichpunkte oder Kurzsätze. Die gesamte Antwort sollte nicht länger als eine Zeilen sein.`;
            } else {
                foreignPrompt = `Du bist ein Sprachenexperte und hilfst bei der präzisen Übersetzung von Wörtern. Übersetze das Wort "${germanWord}" in die Sprache ${language.language}. Antworte ausschließlich mit der genauen Übersetzung, ohne Einleitungen, Erklärungen oder zusätzliche Kommentare. Bevorzuge kurze, einwortige Antworten, sofern sinnvoll. Vermeide vollständige Sätze und setze kein abschließendes Satzzeichen, es sei denn, es ist Teil der Übersetzung.

**Beispiele:**

- Bei der Anfrage "Baum" soll die Antwort "tree" lauten, nicht "Ein Baum heißt auf Englisch 'tree'".
- Auf "laufen" antworte mit "to run", nicht "laufen bedeutet to run".
- Wenn "schnell" übersetzt werden soll, gib "fast" als Antwort, nicht "schnell heißt fast".`;
                zusatzinfoPrompt = `Du bist ein Sprachenexperte und unterstützt beim Vokabellernen, indem du präzise und relevante Zusatzinformationen bereitstellst. Für das Wort "${germanWord}" in der Sprache ${language.language} gib bitte die wichtigsten grammatikalischen und semantischen Informationen an, abhängig von der Wortart und den Besonderheiten der Sprache. Beachte dabei folgende Richtlinien:

**Allgemeine Anweisungen:**

- **Sprache:** Antworte ausschließlich in ${language.language}, nicht in Deutsch.
- **Formatierung:** Formuliere deine Antwort kompakt und ohne Einleitungen oder Füllwörter.
- **Struktur:** Verwende klare und eindeutige Abkürzungen für Kategorien.
- **Trennung:** Trenne die Informationen durch Kommata oder Semikola.
- **Zeilenumbrüche:** Antworte in einer einzigen Zeile ohne Zeilenumbrüche.
- **Relevanz:** Nenne nur die für das Wort relevanten Kategorien und lasse irrelevante weg.
- **Satzzeichen:** Setze kein abschließendes Satzzeichen.

**Spezifische Anweisungen nach Wortart:**

- **Substantive:**
  - **Genus:** Gib das grammatikalische Geschlecht an (z.B. m., f., n.).
  - **Plural:** Nenne die Pluralform, besonders bei unregelmäßigen Formen.
  - **Deklination:** Falls relevant, gib die Deklinationsklasse oder besondere Kasusendungen an.

- **Verben:**
  - **Konjugation:** Nenne wichtige Konjugationsformen, insbesondere bei unregelmäßigen Verben.
  - **Stammformen:** Liste Stammformen oder unregelmäßige Zeitformen auf.
  - **Transitivität:** Gib an, ob das Verb transitiv, intransitiv oder reflexiv ist.

- **Adjektive:**
  - **Steigerung:** Gib Komparativ und Superlativ an, besonders bei unregelmäßigen Formen.
  - **Deklination:** Falls relevant, Hinweise zur Deklination oder besonderen Endungen.

- **Adverbien:**
  - **Steigerung:** Nenne Steigerungsformen, falls vorhanden.
  - **Besonderheiten:** Weisen auf unregelmäßige Formen oder Ausnahmen hin.

- **Zahlwörter und Pronomen:**
  - **Deklination:** Gib wichtige Flexionsformen oder besondere Fälle an.
  - **Verwendung:** Hinweise auf spezielle Gebrauchskontexte oder Einschränkungen.

- **Sprachspezifische Merkmale:**
  - **Tonhöhe:** In Tonsprachen relevante Toninformationen angeben.
  - **Aspekt/Modus:** In Sprachen, die Aspekte oder Modi betonen, entsprechende Informationen hinzufügen.
  - **Besonderheiten:** Alle weiteren für die Zielsprache spezifischen grammatikalischen Merkmale, die für die Verwendung des Wortes entscheidend sind.

**Beispiele:**

- **Englisches Verb:**
  - *to eat*: unregelm. Verb, Past Tense "ate", Past Participle "eaten", transitiv

- **Spanisches Substantiv:**
  - *el agua*: f., Plural "las aguas"

- **Französisches Adjektiv:**
  - *beau*: m., f. "belle", Pl. m. "beaux", Komp. "plus beau", Sup. "le plus beau"

**Spezifische Anweisungen für Latein:**

- **Substantive:**
  - **Genitiv Singular:** Gib den Genitiv Singular an (z.B. "amicus, -i").
  - **Genus:** Gib das grammatikalische Geschlecht an (m., f., n.).
  - **Deklination:** Nenne die Deklinationsklasse (1.-5. Deklination).
  - **Besondere Formen:** Gib unregelmäßige oder besondere Pluralformen an.

- **Verben:**
  - **Stammformen:** Liste die vier Stammformen auf (Präsens, Infinitiv Präsens, Perfekt, Supinum), z.B. "lego, legere, legi, lectum".
  - **Konjugation:** Gib die Konjugationsklasse an (a-, e-, i-, kons.-Konjugation).
  - **Transitivität:** Gib an, ob das Verb transitiv, intransitiv oder reflexiv ist.
  - **Besonderheiten:** Weisen auf unregelmäßige Formen oder Besonderheiten hin.

- **Adjektive:**
  - **Genitiv Singular:** Nenne den Genitiv Singular (z.B. "dives, divitis").
  - **Deklination:** Gib an, nach welcher Deklination das Adjektiv dekliniert wird (z.B. 3. Deklination).
  - **Ablativ Singular:** Füge Informationen zum Ablativ Singular hinzu (z.B. Abl. Sg. auf "-i" oder "-e").
  - **Nominativ Plural Neutrum:** Gib besondere Nominativ Plural Formen an (z.B. "-a" für Neutra).
  - **Genitiv Plural:** Füge den Genitiv Plural hinzu (z.B. "-ium" bei i-Stämmen).
  - **Besonderheiten:** Ergänze relevante Informationen bei unregelmäßiger Deklination.

- **Adverbien:**
  - **Kennzeichnung:** Gib an, dass es sich um ein Adverb handelt.
  - **Steigerung:** Nenne Komparativ und Superlativ, falls vorhanden.
  - **Besonderheiten:** Weisen auf unregelmäßige Formen hin.

- **Pronomen und Zahlwörter:**
  - **Deklination:** Gib die vollständige Deklination oder wichtige Formen an.
  - **Besonderheiten:** Hinweise auf unregelmäßige Formen oder spezielle Verwendungsweisen.

**Lateinische Beispiele:**

- **Substantiv:**
  - *mensa*: Gen. Sg. "mensae", f., 1. Deklination

- **Verb:**
  - *venio*: Stammformen "venio, venire, veni, ventum", i-Konj., intransitiv

- **Adjektiv:**
  - *celer*: Gen. Sg. "celeris", 3. Deklination, Abl. Sg. "celeri", Nom. Pl. n. "celeria", Gen. Pl. "celerium"

- **Adverb:**
  - *fortiter*: Adverb, Komp. "fortius", Sup. "fortissime"

Bitte verwende dieses Format, um die Zusatzinformationen zum Wort "${germanWord}" bereitzustellen, insbesondere unter Berücksichtigung der Besonderheiten der jeweiligen Sprache.`;
            }

            try {
                const foreignResponse = await getResponse(foreignPrompt);
                setForeignWord(foreignResponse);

                const zusatzinfoResponse = await getResponse(zusatzinfoPrompt);
                setAdditionalInfo(zusatzinfoResponse);
            } catch (error) {
                console.error('Error getting AI response:', error);
            }
        }
    };

    const handleForeignBlur = () => {
        if (!foreignWord && germanWord && isAiTranslationActive) {
            handleGermanBlur();
        }
    };

    const handleAdditionalInfoBlur = () => {
        if (!additionalInfo && germanWord && isAiTranslationActive) {
            handleGermanBlur();
        }
    };

    const handlePasteImport = async () => {
        const pastedData = await navigator.clipboard.readText();
        const rows = pastedData.split('\n').map(row => {
            const [germanWord, foreignWord, additionalInfo, tagsString = ''] = row.split('\t');
            
            // Parse tags string into attributes object
            const attributes = {};
            if (tagsString.trim()) {
                // Handle both formats
                if (tagsString.includes(':')) {
                    // Key-Value format
                    tagsString.split(';').forEach(pair => {
                        const [key, value] = pair.split(':').map(s => s.trim());
                        if (key && value) {
                            attributes[key] = value;
                        }
                    });
                } else {
                    // Simple tags format
                    tagsString.split(',').forEach(tag => {
                        const trimmedTag = tag.trim();
                        if (trimmedTag) {
                            attributes[trimmedTag] = trimmedTag;
                        }
                    });
                }
            }
    
            return {
                germanWord: germanWord ? germanWord.trim() : '',
                foreignWord: foreignWord ? foreignWord.trim() : '',
                additionalInfo: additionalInfo ? additionalInfo.trim() : '',
                attributes: attributes
            };
        }).filter(vocab => vocab.germanWord && vocab.foreignWord);
    
        setImportedVocabs(rows);
        setOpenImportDialog(true);
    };

        const handleImportConfirm = async () => {
        const nowGMT = new Date().toISOString();
        for (const vocab of importedVocabs) {
            await addVocab(
                vocab.foreignWord,
                vocab.germanWord,
                '0',
                language.id,
                nowGMT,
                vocab.additionalInfo || undefined,
                vocab.attributes || {}
            );
        }
        setOpenImportDialog(false);
        setImportedVocabs([]);
        setAttributes({});
    };

    const handleImportCancel = () => {
        setOpenImportDialog(false);
        setImportedVocabs([]);
    };

    const handleVocabChange = (index, field, value) => {
        const updatedVocabs = [...importedVocabs];
        updatedVocabs[index][field] = value;
        setImportedVocabs(updatedVocabs);
    };

    const handleVocabDelete = (index) => {
        const updatedVocabs = importedVocabs.filter((_, i) => i !== index);
        setImportedVocabs(updatedVocabs);
    };

    // Neuer Handler für die Attribute
    const handleAttributesChange = (newAttributes) => {
        setAttributes(newAttributes);
    };

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isAiTranslationActive}
                                    onChange={handleAiTranslationChange}
                                    name="aiTranslationSwitch"
                                    color="primary"
                                />
                            }
                            label="KI Übersetzung"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isThreeColumnMode}
                                    onChange={handleColumnModeChange}
                                    name="columnModeSwitch"
                                    color="primary"
                                />
                            }
                            label="Drei Spalten Modus"
                        />
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handlePasteImport}
                                disableElevation
                                style={{ marginRight: '8px' }}
                            >
                                Massenimport
                            </Button>
                            <Button
                                size="small"
                                onClick={handlePopoverOpen}
                                startIcon={<HelpOutlineIcon />}
                            >
                                Hilfe
                            </Button>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Paper elevation={3}>
                                    {importInstructions}
                                </Paper>
                            </Popover>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={isThreeColumnMode ? 4 : 4.5}>
                    <TextField
                        fullWidth
                        label={language.isSubjectTopic ? "Begriff" : "Wort auf Deutsch"}
                        variant="outlined"
                        value={germanWord}
                        onChange={(e) => setGermanWord(e.target.value)}
                        onBlur={handleGermanBlur}
                    />
                </Grid>
                <Grid item xs={12} sm={isThreeColumnMode ? 4 : 4.5}>
                    <TextField
                        fullWidth
                        label={language.isSubjectTopic ? "Definition/Erklärung" : `Wort auf ${language.language}`}
                        variant="outlined"
                        value={foreignWord}
                        onChange={(e) => setForeignWord(e.target.value)}
                        onBlur={handleForeignBlur}
                        onKeyDown={handleKeyDown}
                    />
                </Grid>
                {isThreeColumnMode && (
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Zusatzinfo"
                            variant="outlined"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                            onBlur={handleAdditionalInfoBlur}
                            onKeyDown={handleKeyDown}
                        />
                    </Grid>
                )}
                
                {/* Neue Attribute Sektion */}
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mb={1}>
                        <Button
                            size="small"
                            onClick={() => setShowAttributes(!showAttributes)}
                            endIcon={showAttributes ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        >
                            Attribute {showAttributes ? "ausblenden" : "anzeigen"}
                        </Button>
                    </Box>
                    <Collapse in={showAttributes}>
                        <Box mb={2}>
                            <AttributeInput
                                languageId={language.id}
                                initialAttributes={attributes}
                                onChange={handleAttributesChange}
                            />
                        </Box>
                    </Collapse>
                </Grid>

                <Grid item xs={12} sm={isThreeColumnMode ? 12 : 3}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAdd}
                            disableElevation
                        >
                            <AddIcon />
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={openDialog} onClose={handleCancelAdd}>
                <DialogTitle>Wort bereits vorhanden</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Das Wort "{foreignWord}" existiert bereits in der Datenbank:
                        {existingWord && (
                            <>
                                <br />
                                Deutsch: {existingWord.german}
                                <br />
                                {language.language}: {existingWord.foreign}
                                {existingWord.additionalInfo && (
                                    <>
                                        <br />
                                        Zusatzinfo: {existingWord.additionalInfo}
                                    </>
                                )}
                        {existingWord.attributes && Object.keys(existingWord.attributes).length > 0 && (
                            <>
                                <br />
                                Attribute: {Object.entries(existingWord.attributes)
                                    .map(([key, value]) => {
                                        const valueLower = value.toLowerCase();
                                        const keyLower = key.toLowerCase();
                                        return valueLower.includes(keyLower) ? value : `${key}: ${value}`;
                                    })
                                    .join(', ')}
                            </>
                        )}                            </>
                        )}
                        <br /><br />
                        Möchten Sie es trotzdem hinzufügen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelAdd} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleConfirmAdd} color="primary" autoFocus>
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openImportDialog} onClose={handleImportCancel} maxWidth="md" fullWidth>
                <DialogTitle>Importierte Vokabeln überprüfen</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Button onClick={handleImportConfirm} color="primary">
                                    OK
                                </Button>
                                <Button onClick={handleImportCancel} color="secondary">
                                    Abbrechen
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}><Typography variant="h6">Deutsch</Typography></Grid>
                                <Grid item xs={3}><Typography variant="h6">{language.language}</Typography></Grid>
                                <Grid item xs={3}><Typography variant="h6">Zusatzinfo</Typography></Grid>
                                <Grid item xs={3}><Typography variant="h6">Attribute</Typography></Grid>
                            </Grid>
                            {importedVocabs.map((vocab, index) => (
                                <Grid container spacing={2} key={index} alignItems="center">
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            value={vocab.germanWord}
                                            onChange={(e) => handleVocabChange(index, 'germanWord', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            value={vocab.foreignWord}
                                            onChange={(e) => handleVocabChange(index, 'foreignWord', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            fullWidth
                                            value={vocab.additionalInfo}
                                            onChange={(e) => handleVocabChange(index, 'additionalInfo', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <AttributeInput
                                            languageId={language.id}
                                            initialAttributes={vocab.attributes || {}}
                                            onChange={(newAttributes) => {
                                                const updatedVocabs = [...importedVocabs];
                                                updatedVocabs[index].attributes = newAttributes;
                                                setImportedVocabs(updatedVocabs);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button onClick={() => handleVocabDelete(index)} color="secondary">
                                            X
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}