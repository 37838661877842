// src/components/GetStarted.js
import React from 'react';
import { Typography, Paper, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import PostAddIcon from '@mui/icons-material/PostAdd';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export default function GetStarted({ activeTab }) {
  const getStepContent = () => {
    switch (activeTab) {
      case 0: // Inhaltsverwaltung
        return "Um Vokabeln oder Lerninhalte anlegen zu können, müssen Sie zunächst eine Sprache auswählen.";
      case 1: // Lernen
        return "Um mit dem Lernen zu beginnen, legen Sie bitte in der Inhaltsverwaltung erst Vokabeln oder Lerninhalte an.";
      case 2: // Wiederholen
        return "Für den Wiederholungsmodus werden Vokabeln oder Lerninhalte benötigt. Bitte legen Sie diese in der Inhaltsverwaltung an.";
      case 3: // Sprachübung
        return "Die Sprachübung steht zur Verfügung, sobald Sie Vokabeln oder Lerninhalte in der Inhaltsverwaltung angelegt haben.";
      default:
        return "Wählen Sie einen Bereich aus, um spezifische Anweisungen zu erhalten.";
    }
  };

  // Erstellen einer Wrapper-Komponente für die Icons
  const StepIcon = ({ icon }) => {
    const IconComponent = {
      1: LanguageIcon,
      2: PostAddIcon,
      3: MenuBookIcon
    }[icon];

    return IconComponent ? <IconComponent /> : null;
  };

  return (
    <Paper sx={{ p: 3, mt: 2 }}>
      <Typography variant="h5" gutterBottom>
        Erste Schritte
      </Typography>
      
      <Stepper orientation="vertical" sx={{ mt: 3 }}>
        <Step completed={false}>
          <StepLabel StepIconComponent={StepIcon}>
            Sprache anlegen
          </StepLabel>
          <StepContent>
            <Typography>
            Erstelle deine erste Sprache, um mit dem Lernen zu beginnen. Möchtest du stattdessen lieber mit einem bestimmten Sachthema starten, aktiviere das Sachthema-Icon neben dem Eingabefeld.
            </Typography>
          </StepContent>
        </Step>

        <Step completed={false}>
          <StepLabel StepIconComponent={StepIcon}>
            Vokabeln hinzufügen
          </StepLabel>
          <StepContent>
            <Typography>
              Nach Auswahl einer Sprache können Sie in der Inhaltsverwaltung Ihre Vokabelpaare oder 
              Sachthemen-Karten einzeln eingeben oder mehrere auf einmal importieren.
            </Typography>
          </StepContent>
        </Step>

        <Step completed={false}>
          <StepLabel StepIconComponent={StepIcon}>
            Starten Sie mit dem Lernen
          </StepLabel>
          <StepContent>
            <Typography>
              {getStepContent()}
            </Typography>
          </StepContent>
        </Step>
      </Stepper>
    </Paper>
  );
}