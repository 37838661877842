import { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from '../firebase/config';
import { db } from '../firebase/config';
import { doc, setDoc } from 'firebase/firestore';

export function useSignup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');  // Neues State-Field für den Namen
  const [error, setError] = useState(null);

  const signup = async () => {
    try {
      // Validate name
      if (!name.trim()) {
        throw new Error('Bitte gib deinen Namen ein.');
      }

      // Erstelle den Firebase Auth User
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Update das Auth Profil mit dem Namen
      await updateProfile(userCredential.user, {
        displayName: name.trim()
      });

      // Initialisiere den Firestore Eintrag für den neuen User
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      await setDoc(userDocRef, {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        displayName: name.trim(),  // Name vom Formular
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
        roles: ['user'],
        verified: false,
        subscriptionStatus: 'inactive',
        subscription: {
          status: 'inactive',
          features: {
            maxVocabs: 50,
            aiTranslations: false,
            grammarCheck: false,
            speechRecognition: false
          }
        },
        activityLog: [{
          action: 'account_created',
          timestamp: new Date().toISOString(),
          details: 'User account created'
        }],
        lastUpdated: new Date().toISOString()
      });

    } catch (error) {
      console.error('Signup error:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError('Diese E-Mail ist schon vergeben. Vielleicht willst du dich einloggen?');
      } else if (error.code === 'auth/weak-password') {
        setError('Dein Passwort könnte stärker sein. Wie wärs mit was Kreativerem?');
      } else {
        setError(error.message);
      }
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    name,      // Neues Name-Field im Return
    setName,   // Setter für den Namen
    signup,
    error
  };
}