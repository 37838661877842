import { useCallback, useState, useRef } from 'react';
import openai from './openai';

function useOpenAiTextToSpeech() {
  const [isStreaming, setIsStreaming] = useState(false);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const audioElementRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const pendingBuffersRef = useRef([]);
  const currentRequestRef = useRef(null);

  const createAudioContext = useCallback(() => {
    if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    return audioContextRef.current;
  }, []);

  const stopCurrentAudio = useCallback(() => {
    if (sourceNodeRef.current) {
      sourceNodeRef.current.stop();
      sourceNodeRef.current.disconnect();
      sourceNodeRef.current = null;
    }
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      audioElementRef.current.src = '';
    }
    if (mediaSourceRef.current && mediaSourceRef.current.readyState === 'open') {
      try {
        endOfStreamWhenReady(mediaSourceRef.current);
      } catch (error) {
        console.error('Error ending media stream:', error);
      }
    }
    setIsStreaming(false);
    pendingBuffersRef.current = [];
  }, []);

  const ensureAudioContextReady = useCallback(async (audioContext) => {
    if (audioContext.state === 'suspended') {
      await audioContext.resume();
    }
    return new Promise((resolve) => {
      if (audioContext.state === 'running') {
        resolve();
      } else {
        audioContext.addEventListener('statechange', function handler() {
          if (audioContext.state === 'running') {
            audioContext.removeEventListener('statechange', handler);
            resolve();
          }
        });
      }
    });
  }, []);

  const appendNextBuffer = useCallback(() => {
    if (pendingBuffersRef.current.length && sourceBufferRef.current && !sourceBufferRef.current.updating) {
      const nextBuffer = pendingBuffersRef.current.shift();
      try {
        sourceBufferRef.current.appendBuffer(nextBuffer);
      } catch (error) {
        console.error('Error appending buffer:', error);
      }
    }
  }, []);

  const endOfStreamWhenReady = useCallback((mediaSource) => {
    if (mediaSource.readyState === 'open') {
      if (!mediaSource.sourceBuffers.length || !mediaSource.sourceBuffers[0].updating) {
        mediaSource.endOfStream();
      } else {
        setTimeout(() => endOfStreamWhenReady(mediaSource), 100);
      }
    }
  }, []);

  const clearMediaSource = useCallback(() => {
    if (mediaSourceRef.current) {
      if (mediaSourceRef.current.readyState === 'open') {
        try {
          while (mediaSourceRef.current.sourceBuffers.length > 0) {
            mediaSourceRef.current.removeSourceBuffer(mediaSourceRef.current.sourceBuffers[0]);
          }
          mediaSourceRef.current.endOfStream();
        } catch (error) {
          console.error('Error clearing media source:', error);
        }
      }
      mediaSourceRef.current = null;
    }
    sourceBufferRef.current = null;
  }, []);

  const setupMediaSource = useCallback(() => {
    return new Promise((resolve) => {
      mediaSourceRef.current = new MediaSource();
      audioElementRef.current = new Audio();
      audioElementRef.current.src = URL.createObjectURL(mediaSourceRef.current);

      mediaSourceRef.current.addEventListener('sourceopen', () => {
        if (!mediaSourceRef.current.sourceBuffers.length) {
          sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg');
          sourceBufferRef.current.addEventListener('updateend', appendNextBuffer);
        }
        resolve();
      }, { once: true });
    });
  }, [appendNextBuffer]);

  const playText = useCallback(async (text) => {
    if (currentRequestRef.current) {
      currentRequestRef.current.abort();
    }

    stopCurrentAudio();
    clearMediaSource();
    setIsStreaming(true);

    const controller = new AbortController();
    currentRequestRef.current = controller;

    try {
      const audioContext = createAudioContext();
      await ensureAudioContextReady(audioContext);

      await setupMediaSource();

      const response = await openai.audio.speech.create({
        model: "tts-1-hd",
        voice: "fable",
        response_format: "mp3",
        input: text,
        signal: controller.signal,
      });

      const reader = response.body.getReader();

      audioElementRef.current.oncanplay = () => {
        if (!controller.signal.aborted) {
          audioElementRef.current.play().catch(error => {
            console.error('Error playing audio:', error);
          });
        }
      };

      audioElementRef.current.onended = () => {
        setIsStreaming(false);
        clearMediaSource();
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done || controller.signal.aborted) break;
        
        pendingBuffersRef.current.push(value);
        if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
          appendNextBuffer();
        }
      }

      if (!controller.signal.aborted) {
        endOfStreamWhenReady(mediaSourceRef.current);
      }

    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('TTS request aborted');
      } else {
        console.error('Error in playText:', err);
        setIsStreaming(false);
        stopCurrentAudio();
        clearMediaSource();
        throw err;
      }
    } finally {
      currentRequestRef.current = null;
    }
  }, [
    stopCurrentAudio, 
    createAudioContext, 
    ensureAudioContextReady, 
    appendNextBuffer, 
    endOfStreamWhenReady, 
    clearMediaSource,
    setupMediaSource
  ]);

  return { playText, isStreaming };
}

export default useOpenAiTextToSpeech;