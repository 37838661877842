import React, { useState, useEffect, useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { useVocabList } from '../hooks/useVocabList';
import { useSelector } from 'react-redux';
import { TagFilter } from './TagFilter';
import VocabCard from './VocabCard'
import {
    Card,
    CardContent,
    Typography,
    LinearProgress,
    Box,
    Switch,
    FormControlLabel,
    Button,
    Select,
    MenuItem,
    useMediaQuery,
    useTheme,
    IconButton,
    Menu,
    Chip,
    Divider
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function LearningPage() {
    const [user] = useAuthState(auth);
    const userId = user ? user.uid : null;
    const language = useSelector(state => state.language);
    const { vocabPairs, error } = useVocabList(userId, language);

    const [showGerman, setShowGerman] = useState({});
    const [defaultGerman, setDefaultGerman] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [maxKnownCounter, setMaxKnownCounter] = useState(0);
    const [highestPageReached, setHighestPageReached] = useState(-1);
    const [tagFilters, setTagFilters] = useState({});
    const [showFilters, setShowFilters] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleSettingsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    const filteredVocabPairs = useMemo(() => {
        let filtered = [...vocabPairs];
        
        // Filter by known counter
        filtered = filtered.filter(vocabPair => 
            vocabPair.knownCounter <= maxKnownCounter
        );

        // Filter by tags
        const hasActiveTagFilters = Object.values(tagFilters).some(values => 
            Object.values(values).some(isActive => isActive)
        );

        if (hasActiveTagFilters) {
            filtered = filtered.filter(vocab => {
                return Object.entries(tagFilters).every(([key, values]) => {
                    const activeValues = Object.entries(values)
                        .filter(([_, isActive]) => isActive)
                        .map(([value]) => value);
                    if (activeValues.length === 0) return true;
                    return vocab.attributes && 
                           vocab.attributes[key] && 
                           activeValues.includes(vocab.attributes[key]);
                });
            });
        }

        return filtered;
    }, [vocabPairs, maxKnownCounter, tagFilters]);

    const totalPages = Math.ceil(filteredVocabPairs.length / itemsPerPage);

    const pagesLearned = highestPageReached + 1;
    const progress = totalPages > 1 ? (pagesLearned / totalPages) * 100 : 0;

    const handleNext = () => {
        const nextPage = Math.min(currentPage + 1, totalPages - 1);
        setCurrentPage(nextPage);
        setHighestPageReached(prevHighest => Math.max(prevHighest, currentPage));
    };

    const handlePrev = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    useEffect(() => {
        const initialShowGerman = {};
        vocabPairs.forEach(vocabPair => {
            initialShowGerman[vocabPair.id] = defaultGerman;
        });
        setShowGerman(initialShowGerman);
    }, [vocabPairs, defaultGerman]);

    const handleCardClick = (id) => {
        setShowGerman(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };

    useEffect(() => {
        setCurrentPage(0);
        setHighestPageReached(-1);
    }, [maxKnownCounter, itemsPerPage, tagFilters]);

    const handleToggleFilters = () => {
        setShowFilters(!showFilters);
    };
    const renderSettings = () => (
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', gap: 2, mb: 2 }}>
            <FormControlLabel
                control={<Switch checked={defaultGerman} onChange={() => setDefaultGerman(!defaultGerman)} />}
                label={defaultGerman ? `Deutsch zu ${language.language}` : `${language.language} zu Deutsch`}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2" component="span">
                    Vokabeln bis
                </Typography>
                <Select
                    value={maxKnownCounter}
                    onChange={(event) => setMaxKnownCounter(event.target.value)}
                    size="small"
                >
                    {[...Array(6).keys()].map((value) => (
                        <MenuItem key={value} value={value}>
                            {value === 0 ? 'Nie gewusst' : `${value} mal`}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2" component="span">
                    Pro Seite
                </Typography>
                <Select
                    value={itemsPerPage}
                    onChange={(event) => setItemsPerPage(event.target.value)}
                    size="small"
                >
                    {[...Array(8).keys()].map((_, index) => (
                        <MenuItem key={index + 3} value={index + 3}>{index + 3}</MenuItem>
                    ))}
                </Select>
            </Box>
            <Button
                startIcon={<FilterListIcon />}
                onClick={handleToggleFilters}
                variant={showFilters ? "contained" : "outlined"}
                size="small"
            >
                Tags
            </Button>
        </Box>
    );

    if (!language) {
        return <LinearProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">Error loading vocab list: {error.message}</Typography>;
    }

    if (!vocabPairs) {
        return <LinearProgress />;
    }

    return (
        <div>
            <Box sx={{ width: '100%', mb: 2 }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Lernfortschritt: {pagesLearned} von {totalPages} Seiten durchlaufen
                </Typography>
                <LinearProgress variant="determinate" value={progress} />
            </Box>

            {isMobile ? (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                        <IconButton onClick={handleSettingsClick}>
                            <SettingsIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleSettingsClose}
                    >
                        <Box sx={{ p: 2 }}>
                            {renderSettings()}
                        </Box>
                    </Menu>
                </>
            ) : (
                renderSettings()
            )}

            {showFilters && (
                <Box mb={2}>
                    <TagFilter 
                        vocabs={vocabPairs} 
                        onFilterChange={setTagFilters}
                    />
                </Box>
            )}

{filteredVocabPairs
    .sort((a, b) => a.german.localeCompare(b.german))
    .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
    .map((vocabPair) => (
        <VocabCard
            key={vocabPair.id}
            vocabPair={vocabPair}
            showGerman={showGerman[vocabPair.id]}
            onCardClick={handleCardClick}
        />
    ))}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button onClick={handlePrev} disabled={currentPage === 0}>Prev</Button>
                <Button onClick={handleNext} disabled={currentPage >= totalPages - 1}>Next</Button>
            </Box>
        </div>
    );
}