import { useState, useEffect, useCallback, useRef } from 'react';
import { auth } from '../firebase/config';

const POLLING_INTERVAL = 30000; // 30 Sekunden
const API_BASE_URL = 'https://brainwizzr-backend-41f7251d9520.herokuapp.com';

// Status-Typen, die kein weiteres Polling erfordern
const FINAL_STATUSES = ['active', 'cancelled', 'expired', 'inactive'];

export const useSubscriptionStatus = () => {
  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const pollingTimeoutRef = useRef(null);
  const abortControllerRef = useRef(null);

  const cleanup = useCallback(() => {
    if (pollingTimeoutRef.current) {
      clearTimeout(pollingTimeoutRef.current);
      pollingTimeoutRef.current = null;
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  }, []);

  const shouldContinuePolling = useCallback((data) => {
    // Stoppe Polling wenn:
    // 1. Die Zahlung als abgebrochen markiert wurde
    if (data?.pendingPayment?.mollieStatus === 'canceled') {
      return false;
    }
    // 2. Der Status "pending" ist aber älter als 30 Minuten
    if (data?.pendingPayment) {
      const paymentDate = new Date(data.pendingPayment.createdAt);
      const timeDiff = Date.now() - paymentDate.getTime();
      const minutesDiff = Math.floor(timeDiff / 1000 / 60);
      if (minutesDiff >= 30) {
        return false;
      }
    }
    // Fortsetzen wenn Status pending ist und Zahlung noch aktuell
    return data?.status === 'pending' || 
           (data?.pendingPayment && !FINAL_STATUSES.includes(data.status));
  }, []);

  const fetchSubscriptionStatus = useCallback(async (signal) => {
    if (!auth.currentUser) return;

    try {
      setRefreshing(true);
      setError(null);
      
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch(`${API_BASE_URL}/api/subscriptions/status`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSubscription(data);

      // Prüfe ob weiteres Polling nötig ist
      if (shouldContinuePolling(data)) {
        pollingTimeoutRef.current = setTimeout(() => {
          abortControllerRef.current = new AbortController();
          fetchSubscriptionStatus(abortControllerRef.current.signal);
        }, POLLING_INTERVAL);
      }

    } catch (error) {
      if (error.name === 'AbortError') return;
      
      console.error('Error fetching subscription:', error);
      setError('Fehler beim Laden der Abodaten');
      
      // Bei Fehlern nur weiterpollen wenn der letzte Status es rechtfertigt
      if (shouldContinuePolling(subscription)) {
        pollingTimeoutRef.current = setTimeout(() => {
          abortControllerRef.current = new AbortController();
          fetchSubscriptionStatus(abortControllerRef.current.signal);
        }, POLLING_INTERVAL);
      }
    } finally {
      setRefreshing(false);
    }
  }, [subscription, shouldContinuePolling]);

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    fetchSubscriptionStatus(abortControllerRef.current.signal);

    return () => cleanup();
  }, [cleanup]);

  const refreshStatus = useCallback(() => {
    cleanup();
    abortControllerRef.current = new AbortController();
    fetchSubscriptionStatus(abortControllerRef.current.signal);
  }, [cleanup]);

  return {
    subscription,
    error,
    refreshing,
    refreshStatus
  };
};