import React, { useState, useEffect, useCallback } from 'react';
import { auth, db } from '../firebase/config';
import { updateProfile, updateEmail, updatePassword, sendEmailVerification } from 'firebase/auth';
import { collection, query, getDocs } from 'firebase/firestore';
import { 
  Container, Typography, TextField, Button, Grid, Paper, IconButton,
  Snackbar, Alert, useTheme, useMediaQuery
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SubscriptionSection from './SubscriptionSection';

const Profile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [usageStats, setUsageStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [debugInfo, setDebugInfo] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchUsageStats = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    let debugText = `Fetching stats for user: ${userId}\n`;
    try {
      const statsRef = collection(db, 'userStats', userId, 'dailyStats');
      const q = query(statsRef);
      debugText += `Query: ${JSON.stringify(q)}\n`;
      const querySnapshot = await getDocs(q);
      debugText += `Query snapshot size: ${querySnapshot.size}\n`;
      
      let allStats = querySnapshot.docs.map(doc => {
        debugText += `Document ID: ${doc.id}, Data: ${JSON.stringify(doc.data())}\n`;
        return {
          ...doc.data(),
          date: doc.id,
        };
      });

      debugText += `All stats: ${JSON.stringify(allStats)}\n`;
      setUsageStats(allStats);
    } catch (err) {
      debugText += `Error: ${err.message}\n`;
      setError('Fehler beim Laden der Nutzungsstatistiken: ' + err.message);
    } finally {
      setLoading(false);
      setDebugInfo(debugText);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setDisplayName(user.displayName || '');
        setEmail(user.email || '');
        fetchUsageStats(user.uid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [fetchUsageStats]);

  const getMonthData = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const monthData = Array.from({ length: daysInMonth }, (_, i) => ({
      date: `${year}-${String(month + 1).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`,
      activeMinutes: 0
    }));

    usageStats.forEach(stat => {
      const statDate = new Date(stat.date);
      if (statDate.getFullYear() === year && statDate.getMonth() === month) {
        const dayIndex = statDate.getDate() - 1;
        monthData[dayIndex] = { ...monthData[dayIndex], ...stat };
      }
    });

    return monthData;
  };

  const handleUpdateProfile = async () => {
    try {
      await updateProfile(auth.currentUser, { displayName });
      setSnackbar({
        open: true,
        message: 'Profil erfolgreich aktualisiert',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Fehler beim Aktualisieren des Profils: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const handleUpdateEmail = async () => {
    try {
      await updateEmail(auth.currentUser, email);
      await sendEmailVerification(auth.currentUser);
      setSnackbar({
        open: true,
        message: 'E-Mail-Adresse aktualisiert. Bitte überprüfen Sie Ihr Postfach für die Verifizierungs-E-Mail.',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Fehler beim Aktualisieren der E-Mail: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const handleUpdatePassword = async () => {
    try {
      await updatePassword(auth.currentUser, newPassword);
      setSnackbar({
        open: true,
        message: 'Passwort erfolgreich aktualisiert',
        severity: 'success'
      });
      setNewPassword('');
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Fehler beim Aktualisieren des Passworts: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper elevation={3} sx={{ p: 1, bgcolor: 'background.paper' }}>
          <Typography variant="body2">{`Datum: ${new Date(label).toLocaleDateString()}`}</Typography>
          <Typography variant="body2">{`Aktive Minuten: ${payload[0].value}`}</Typography>
        </Paper>
      );
    }
    return null;
  };

  if (!user) return <Typography>Laden...</Typography>;

  const currentMonthData = getMonthData(currentMonth.getFullYear(), currentMonth.getMonth());

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h4" gutterBottom>Profil</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleUpdateProfile}>
              Namen aktualisieren
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleUpdateEmail}>
              E-Mail aktualisieren
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Neues Passwort"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleUpdatePassword}>
              Passwort ändern
            </Button>
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>Nutzungsstatistiken</Typography>
            <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
              <IconButton onClick={handlePreviousMonth}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <Typography variant="h6">
                {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
              </Typography>
              <IconButton onClick={handleNextMonth}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Grid>
            {loading ? (
              <Typography>Lade Statistiken...</Typography>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <Paper elevation={2} sx={{ p: 2, height: 350 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={currentMonthData}
                    margin={{
                      top: 20,
                      right: isMobile ? 10 : 20,
                      left: isMobile ? 0 : 20,
                      bottom: isMobile ? 60 : 20
                    }}
                  >
                    <XAxis 
                      dataKey="date" 
                      tickFormatter={(tick) => new Date(tick).getDate()}
                      interval={isMobile ? 2 : 0}
                      angle={isMobile ? -45 : 0}
                      textAnchor={isMobile ? "end" : "middle"}
                      height={isMobile ? 60 : 30}
                      tick={{ fontSize: isMobile ? 12 : 14 }}
                      tickMargin={isMobile ? 15 : 5}
                    />
                    <YAxis 
                      tick={{ fontSize: isMobile ? 12 : 14 }}
                      width={isMobile ? 30 : 40}
                      tickFormatter={(value) => `${value}m`}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar 
                      dataKey="activeMinutes" 
                      fill={theme.palette.primary.main}
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 3, my: 3 }}>
        <SubscriptionSection />
      </Paper>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Profile;