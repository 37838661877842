import { useState, useCallback, useRef } from 'react';
import openai from './openai';

const useAiVoiceInput = () => {
    const [voiceResponse, setVoiceResponse] = useState('');
    const isProcessingRef = useRef(false);
    const lastProcessedFileRef = useRef(null);

    const getVoiceResponse = useCallback(async (audioFile) => {
        // Prüfen ob es die gleiche Datei ist
        if (lastProcessedFileRef.current === audioFile) {
            console.log('Already processed this file');
            return;
        }

        // Prüfen ob gerade verarbeitet wird
        if (isProcessingRef.current) {
            console.log('Currently processing another file');
            return;
        }

        try {
            isProcessingRef.current = true;
            lastProcessedFileRef.current = audioFile;

            const transcription = await openai.audio.transcriptions.create({
                model: "whisper-1",
                file: audioFile,
            });
            
            setVoiceResponse(transcription.text);
            return transcription.text;
        } catch (error) {
            console.error('Error fetching response from OpenAI:', error);
            throw error;
        } finally {
            isProcessingRef.current = false;
        }
    }, []);

    return { voiceResponse, getVoiceResponse };
};

export default useAiVoiceInput;