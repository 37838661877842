import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Button,
  Collapse,
  Alert
} from '@mui/material';
import { AttributeInput } from './AttributeInput';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

export function BulkAttributeEditor({
  languageId,
  selectedVocabs,
  onApply,
  onCancel
}) {
  const [attributesToAdd, setAttributesToAdd] = useState({});
  const [attributesToRemove, setAttributesToRemove] = useState([]);
  const [mode, setMode] = useState('add'); // 'add' oder 'remove'

  const handleApply = () => {
    onApply({
      attributesToAdd: mode === 'add' ? attributesToAdd : {},
      attributesToRemove: mode === 'remove' ? attributesToRemove : []
    });
  };

  const commonAttributes = selectedVocabs.reduce((common, vocab) => {
    if (!vocab.attributes) return common;
    
    Object.entries(vocab.attributes).forEach(([key, value]) => {
      if (!common[key]) {
        common[key] = { value, count: 1 };
      } else if (common[key].value === value) {
        common[key].count++;
      }
    });
    return common;
  }, {});

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Attribute bearbeiten ({selectedVocabs.length} Vokabeln ausgewählt)
      </Typography>
      
      {selectedVocabs.length > 0 && (
        <>
          <Box sx={{ mb: 2 }}>
            <Button
              variant={mode === 'add' ? 'contained' : 'outlined'}
              onClick={() => setMode('add')}
              sx={{ mr: 1 }}
            >
              Attribute hinzufügen
            </Button>
            <Button
              variant={mode === 'remove' ? 'contained' : 'outlined'}
              onClick={() => setMode('remove')}
            >
              Attribute entfernen
            </Button>
          </Box>

          {mode === 'add' && (
            <Box sx={{ mb: 2 }}>
              <AttributeInput
                languageId={languageId}
                initialAttributes={attributesToAdd}
                onChange={setAttributesToAdd}
              />
            </Box>
          )}

          {mode === 'remove' && Object.keys(commonAttributes).length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Vorhandene Attribute (Klicken zum Entfernen):
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {Object.entries(commonAttributes).map(([key, { value, count }]) => (
                  <Button
                    key={key}
                    variant={attributesToRemove.includes(key) ? "contained" : "outlined"}
                    size="small"
                    onClick={() => {
                      setAttributesToRemove(prev => 
                        prev.includes(key) 
                          ? prev.filter(k => k !== key)
                          : [...prev, key]
                      );
                    }}
                  >
                    {`${key}: ${value} (${count})`}
                  </Button>
                ))}
              </Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApply}
              startIcon={<DoneIcon />}
              disabled={(mode === 'add' && Object.keys(attributesToAdd).length === 0) || 
                       (mode === 'remove' && attributesToRemove.length === 0)}
            >
              Änderungen anwenden
            </Button>
            <Button
              variant="outlined"
              onClick={onCancel}
              startIcon={<ClearIcon />}
            >
              Abbrechen
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
}