import React, { useState } from 'react';
import { Tooltip, Chip } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const PremiumStatusChip = ({ status }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (status === 'pending') {
    return (
      <Tooltip 
        title="Premium wird aktiviert..." 
        open={isTooltipOpen}
        onClose={() => setIsTooltipOpen(false)}
        arrow
      >
        <Chip
          icon={<WorkspacePremiumIcon />}
          onClick={() => setIsTooltipOpen(!isTooltipOpen)}
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
          sx={{ 
            mr: 2,
            backgroundColor: '#ffd54f',
            '& .MuiChip-label': {
              display: 'none'
            },
            '& .MuiChip-icon': {
              color: 'black',
              margin: 0,
              padding: '4px'
            },
            height: '32px',
            width: '32px',
            borderRadius: '16px',
            cursor: 'pointer'
          }}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip 
      title="Premium-User" 
      open={isTooltipOpen}
      onClose={() => setIsTooltipOpen(false)}
      arrow
    >
      <Chip
        icon={<WorkspacePremiumIcon />}
        onClick={() => setIsTooltipOpen(!isTooltipOpen)}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        sx={{ 
          mr: 2,
          backgroundColor: 'gold',
          '& .MuiChip-label': {
            display: 'none'
          },
          '& .MuiChip-icon': {
            color: 'black',
            margin: 0,
            padding: '4px'
          },
          height: '32px',
          width: '32px',
          borderRadius: '16px',
          cursor: 'pointer'
        }}
      />
    </Tooltip>
  );
};

export default PremiumStatusChip;