// SubscriptionManager.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/config';
import { useSubscriptionStatus } from '../hooks/useSubscriptionStatus';
import { 
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  TextField,
  Typography,
  Alert,
  Chip,
  useTheme,
  useMediaQuery,
  Tooltip,
  CircularProgress,
  IconButton
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PendingIcon from '@mui/icons-material/Pending';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';

const API_BASE_URL = 'https://brainwizzr-backend-41f7251d9520.herokuapp.com';

const SUBSCRIPTION_PLANS = {
  monthly: {
    id: 'monthly',
    name: 'Monatsabo',
    price: 7.99,
    interval: 'monthly',
    description: 'Flexibles monatliches Abo mit allen Premium-Funktionen',
    features: [
      'Unbegrenzte Vokabeln',
      'AI-unterstützte Übersetzungen',
      'Aussprachetraining',
      'Personalisierte Lernpläne'
    ]
  },
  yearly: {
    id: 'yearly',
    name: 'Jahresabo',
    price: 79.99,
    interval: 'yearly',
    description: 'Spare über 16% mit unserem Jahresabo',
    features: [
      'Alle Funktionen des Monatsabos',
      'Zwei Monate gratis',
      'Prioritäts-Support',
      'Offline-Modus'
    ]
  }
};

const getSubscriptionStatus = (data) => {
  if (!data) {
    return { 
      status: 'inactive', 
      label: 'Kein aktives Abo',
      info: null
    };
  }

  // Prüfe zuerst auf abgebrochene Zahlungen
  if (data.pendingPayment?.mollieStatus === 'canceled') {
    return {
      status: 'inactive',
      label: 'Zahlung abgebrochen',
      info: 'Die Zahlung wurde nicht abgeschlossen. Sie können den Vorgang erneut starten.'
    };
  }
  
  // Überprüfe aktive Status
  const isActive = data.firestoreStatus === 'active' || 
                  data.verifiedStatus === 'active' || 
                  (data.subscription?.status === 'active');

  if (isActive) {
    return { 
      status: 'active', 
      label: 'Aktiv',
      info: `Premium${data.plan ? ` (${data.plan})` : ''}`
    };
  }
  
  if (data.pendingPayment) {
    const paymentDate = new Date(data.pendingPayment.createdAt);
    const timeDiff = Date.now() - paymentDate.getTime();
    const minutesDiff = Math.floor(timeDiff / 1000 / 60);
    
    if (minutesDiff < 30 && data.pendingPayment.mollieStatus !== 'canceled') {
      return { 
        status: 'pending', 
        label: 'Zahlung in Bearbeitung',
        info: 'Die Zahlung wird verarbeitet. Dies kann einige Minuten dauern.'
      };
    }
    
    return { 
      status: 'payment-issue', 
      label: 'Zahlungsproblem',
      info: 'Es gab ein Problem bei der Zahlungsverarbeitung. Bitte versuchen Sie es erneut.'
    };
  }

  const statusMap = {
    cancelled: { 
      status: 'cancelled', 
      label: 'Gekündigt',
      info: 'Ihr Abo läuft noch bis zum Ende der Periode.'
    },
    expired: { 
      status: 'expired', 
      label: 'Abgelaufen',
      info: 'Ihr Abo ist abgelaufen. Erneuern Sie jetzt für weitere Premium-Funktionen.'
    },
    inactive: { 
      status: 'inactive', 
      label: 'Kein aktives Abo',
      info: null
    }
  };

  const currentStatus = 
    data.subscription?.status || 
    data.firestoreStatus || 
    'inactive';
                       
  return statusMap[currentStatus] || statusMap.inactive;
};

const SubscriptionPlans = ({ currentPlan, onSelectPlan }) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gap: 3, my: 3 }}>
      {Object.values(SUBSCRIPTION_PLANS).map((plan) => (
        <Card 
          key={plan.id} 
          raised={currentPlan === plan.id}
          sx={{ 
            position: 'relative', 
            height: '100%',
            transition: 'all 0.3s ease-in-out',
            transform: currentPlan === plan.id ? 'scale(1.02)' : 'scale(1)',
            '&:hover': {
              transform: 'scale(1.02)'
            }
          }}
        >
          {currentPlan === plan.id && (
            <Chip
              icon={<StarIcon />}
              label="Ausgewählt"
              color="primary"
              sx={{ position: 'absolute', top: 8, right: 8 }}
            />
          )}
          <CardHeader
            title={
              <Typography variant="h5" component="h2">
                {plan.name}
              </Typography>
            }
            subheader={
              <Box sx={{ mt: 1 }}>
                <Typography variant="h4" color="primary" component="span">
                  {plan.price}€
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="span">
                  /{plan.interval === 'monthly' ? 'Monat' : 'Jahr'}
                </Typography>
              </Box>
            }
          />
          <CardContent>
            <Typography color="text.secondary" paragraph>
              {plan.description}
            </Typography>
            <Box sx={{ mb: 2 }}>
              {plan.features.map((feature, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                  <StarIcon sx={{ mr: 1, fontSize: 16, color: 'primary.main' }} />
                  <Typography variant="body2">{feature}</Typography>
                </Box>
              ))}
            </Box>
            <Button
              variant={currentPlan === plan.id ? "outlined" : "contained"}
              color="primary"
              fullWidth
              onClick={() => onSelectPlan(plan.id)}
              sx={{ mt: 2 }}
            >
              {currentPlan === plan.id ? 'Ausgewählt' : 'Auswählen'}
            </Button>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const SubscriptionManager = () => {
  const { 
    subscription, 
    error: subscriptionError, 
    refreshing, 
    refreshStatus 
  } = useSubscriptionStatus();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeValid, setPromoCodeValid] = useState(false);
  const [promoDetails, setPromoDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const subscriptionState = getSubscriptionStatus(subscription);
  const showUpgradeOption = ['inactive', 'cancelled', 'expired', 'payment-issue'].includes(subscriptionState.status);

  const validatePromoCode = async () => {
    if (!auth.currentUser) return;
    
    setLoading(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch(`${API_BASE_URL}/api/subscriptions/analytics/validate-promo`, { // Geänderter Pfad
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ promoCode, plan: selectedPlan })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Ungültiger Promocode');
      }
  
      const data = await response.json();
      console.log('Promo validation response:', data);
      
      if (data.valid) {
        setPromoCodeValid(true);
        setPromoDetails(data.promoDetails);
        setError(null);
      } else {
        setError(data.message || 'Ungültiger Promocode');
        setPromoCodeValid(false);
        setPromoDetails(null);
      }
    } catch (error) {
      console.error('Error validating promo code:', error);
      setError(error.message || 'Fehler beim Überprüfen des Promocodes');
      setPromoCodeValid(false);
      setPromoDetails(null);
    } finally {
      setLoading(false);
    }
  };

  const getPromoDisplayText = (promoDetails) => {
    if (!promoDetails) return '';

    if (promoDetails.type === 'fixed') {
      return `Erste ${promoDetails.durationMonths} Monate für nur ${promoDetails.value}€!`;
    } else if (promoDetails.type === 'percent') {
      return `${promoDetails.value}% Rabatt auf dein Abo!`;
    }

    return promoDetails.description || '';
  };

  const initiatePayment = async () => {
    if (!auth.currentUser) return;
    if (!selectedPlan) {
      setError('Bitte wählen Sie einen Plan aus');
      return;
    }
  
    setLoading(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      
      // Vereinfachte Payload-Struktur
      const payload = {
        plan: selectedPlan,
        promoCode: promoCodeValid ? promoCode : undefined  // Nur den Code senden
      };
  
      // Entferne promoDetails aus dem Payload, da diese vom Backend verwaltet werden
  
      console.log('Starting payment initiation with:', {
        endpoint: `${API_BASE_URL}/api/subscriptions/create`,
        token: 'Bearer ' + token.substring(0, 10) + '...',
        payload
      });
  
      const response = await fetch(`${API_BASE_URL}/api/subscriptions/create`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
              
      const responseText = await response.text();
      let responseData;
      
      try {
        responseData = JSON.parse(responseText);
      } catch (e) {
        console.error('Failed to parse response as JSON:', responseText);
        throw new Error('Ungültige Serverantwort');
      }
  
      console.log('Server response:', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries()),
        data: responseData
      });
  
      if (!response.ok) {
        if (response.status === 400 && responseData.error === 'User already has an active subscription') {
          await refreshStatus();
          setError('Sie haben bereits ein aktives Abonnement.');
          return;
        }
  
        throw new Error(responseData.error || responseData.message || 
                       `Server Error (${response.status}): ${responseData.details || 'Keine weiteren Details verfügbar'}`);
      }
  
      if (!responseData.checkoutUrl) {
        throw new Error('Keine Checkout-URL vom Server erhalten');
      }
  
      console.log('Redirecting to checkout:', responseData.checkoutUrl);
      window.location.href = responseData.checkoutUrl;
  
    } catch (error) {
      console.error('Detailed payment error:', {
        message: error.message,
        stack: error.stack,
        name: error.name
      });
      
      setError(
        'Fehler beim Starten der Zahlung: ' + 
        (error.message.includes('Server Error') ? error.message : 'Bitte versuchen Sie es später erneut.')
      );
      
      try {
        await refreshStatus();
      } catch (refreshError) {
        console.error('Error refreshing status:', refreshError);
      }
    } finally {
      setLoading(false);
    }
  };
      
  const cancelSubscription = async () => {
    if (!auth.currentUser) return;
    if (!window.confirm('Möchten Sie Ihr Abo wirklich kündigen?')) {
      return;
    }

    setLoading(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch(`${API_BASE_URL}/api/subscriptions/cancel`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      refreshStatus();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setError('Fehler beim Kündigen des Abonnements');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          BrainWizzr Premium
        </Typography>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          Erweitere dein Lernerlebnis mit Premium-Funktionen
        </Typography>
      </Box>

      {subscription && (
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" component="h2">
                  Aktueller Status
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                  <Chip
                    icon={
                      subscriptionState.status === 'active' ? <WorkspacePremiumIcon /> :
                      subscriptionState.status === 'pending' ? <PendingIcon /> :
                      subscriptionState.status === 'payment-issue' ? <ErrorOutlineIcon /> :
                      undefined
                    }
                    label={subscriptionState.label}
                    color={
                      subscriptionState.status === 'active' ? 'success' :
                      subscriptionState.status === 'pending' ? 'warning' :
                      subscriptionState.status === 'payment-issue' ? 'error' :
                      'default'
                    }
                  />
                  {subscriptionState.info && (
                    <Tooltip title={subscriptionState.info}>
                      <InfoOutlinedIcon sx={{ ml: 1, fontSize: 20, color: 'text.secondary' }} />
                    </Tooltip>
                  )}
                  {refreshing && (
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                  )}
                </Box>
              </Box>
              <IconButton 
                onClick={refreshStatus} 
                disabled={refreshing}
                title="Status aktualisieren"
              >
                <RefreshIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {subscription.trialEndsAt && (
                <Typography>
                  Kostenlose Phase endet am: {new Date(subscription.trialEndsAt).toLocaleDateString()}
                </Typography>
              )}
              {subscription.nextBillingDate && (
                <Typography>
                  Nächste Abrechnung: {new Date(subscription.nextBillingDate).toLocaleDateString()}
                </Typography>
              )}
              {subscriptionState.status === 'payment-issue' && (
                <Alert 
                  severity="warning" 
                  sx={{ mt: 2 }}
                  action={
                    <Button color="inherit" size="small" onClick={initiatePayment}>
                      Erneut versuchen
                    </Button>
                  }
                >
                  Wir konnten Ihre letzte Zahlung nicht verarbeiten. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Support.
                </Alert>
              )}
            </Box>
          </CardContent>
        </Card>
      )}

      {subscriptionError && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {subscriptionError}
        </Alert>
      )}

      {showUpgradeOption ? (
        <>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Wähle deinen Plan
            </Typography>
            <SubscriptionPlans
              currentPlan={selectedPlan}
              onSelectPlan={setSelectedPlan}
            />
          </Box>

          <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <TextField
                fullWidth
                label="Promocode"
                placeholder="Hast du einen Promocode?"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                variant="outlined"
                disabled={loading || !selectedPlan} // Deaktiviere, wenn kein Plan ausgewählt ist
                error={error && error.includes('Promocode')}
                helperText={error && error.includes('Promocode') ? error : ''}
              />
              <Button
                variant="outlined"
                onClick={validatePromoCode}
                disabled={loading || !promoCode || !selectedPlan} // Hinzugefügt: !selectedPlan
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Einlösen'
                )}
              </Button>
            </Box>

            {promoCodeValid && promoDetails && (
              <Alert severity="success" icon={<StarIcon />}>
                  {getPromoDisplayText(promoDetails)} {/* Korrigiert: promoDetails statt promoDetails.promoDetails */}
              </Alert>
            )}
            {error && !error.includes('Promocode') && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}

            <Button
              variant="contained"
              onClick={initiatePayment}
              disabled={loading || !selectedPlan}
              fullWidth
              size="large"
              startIcon={loading ? <CircularProgress size={24} /> : <StarIcon />}
            >
              {loading ? 'Wird verarbeitet...' : 'Jetzt Premium werden'}
            </Button>

            <Typography 
              variant="body2" 
              color="text.secondary" 
              align="center" 
              sx={{ mt: 2 }}
            >
              Sicher bezahlen mit SSL-Verschlüsselung. Jederzeit kündbar.
            </Typography>
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
          {subscription?.status === 'active' && (
            <>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                Du genießt bereits alle Premium-Vorteile!
              </Typography>
              <Button 
                variant="outlined"
                color="error"
                onClick={cancelSubscription}
                startIcon={loading ? <CircularProgress size={20} /> : <WorkspacePremiumIcon />}
                disabled={loading}
              >
                {loading ? 'Wird verarbeitet...' : 'Premium-Abo kündigen'}
              </Button>
            </>
          )}
        </Box>
      )}

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Fragen zum Premium-Abo? 
          <Button 
            color="primary" 
            size="small" 
            onClick={() => window.location.href = 'mailto:support@brainwizzr.app'}
          >
            Kontaktiere unseren Support
          </Button>
        </Typography>
      </Box>
    </Container>
  );
};

export default SubscriptionManager;
